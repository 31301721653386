import React from 'react';
import { Box } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';


const ButtonSuccess = () => (
  <Box
    sx={{
      marginLeft: '0.5rem',
      marginTop: '0.125rem',
      marginBottom: '0.125rem',
      width: 24,
      height: 24,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      color: 'success.main',
      fontSize: '1.8rem',
    }}
  >
    <CheckCircleIcon
      fontSize="inherit"
      color="inherit"
    />
  </Box>
);


export default ButtonSuccess;
