import { take, call } from 'redux-saga/effects';

import { CHANGE_LOCATION } from '../actions';
import { changeLocation } from './common';


export function *changeLocationSaga() {
  while (true) {
    const { location } = yield take(CHANGE_LOCATION);

    yield call(changeLocation, location);
  }
}
