import React from 'react';
import { SvgIcon } from '@mui/material';

const LocationsIcon = (props) => (
  <SvgIcon viewBox="0 0 16 24" {...props}>
    <path
      d="M8.00007 0.480469C3.75694 0.480469 0.320068 3.91734 0.320068 8.16047C0.320068 11.6686 2.17257 15.4392 3.99507 18.3605C5.81757 21.2817 7.64007 23.3555 7.64007 23.3555C7.73194 23.4586 7.86319 23.5186 8.00007 23.5186C8.13694 23.5186 8.26819 23.4586 8.36007 23.3555C8.36007 23.3555 10.1844 21.2386 12.0051 18.3005C13.8257 15.3623 15.6801 11.6011 15.6801 8.16047C15.6801 3.91734 12.2432 0.480469 8.00007 0.480469ZM8.00007 1.44047C11.7257 1.44047 14.7201 4.43484 14.7201 8.16047C14.7201 11.2486 12.9744 14.9198 11.1951 17.7905C9.70444 20.1961 8.44257 21.7448 8.00007 22.2755C7.55382 21.7486 6.29194 20.2336 4.80507 17.8505C3.02757 15.0005 1.28007 11.3236 1.28007 8.16047C1.28007 4.43484 4.27444 1.44047 8.00007 1.44047ZM8.00007 5.28047C6.14944 5.28047 4.64007 6.78984 4.64007 8.64047C4.64007 10.4911 6.14944 12.0005 8.00007 12.0005C9.85069 12.0005 11.3601 10.4911 11.3601 8.64047C11.3601 6.78984 9.85069 5.28047 8.00007 5.28047ZM8.00007 6.24047C9.33132 6.24047 10.4001 7.30922 10.4001 8.64047C10.4001 9.97172 9.33132 11.0405 8.00007 11.0405C6.66882 11.0405 5.60007 9.97172 5.60007 8.64047C5.60007 7.30922 6.66882 6.24047 8.00007 6.24047Z"
      fill="black"
    />
  </SvgIcon>
);

export default LocationsIcon;
