import React from 'react';
import { PropTypes } from 'prop-types';
import { withStyles } from 'tss-react/mui';
import { CircularProgress } from '@mui/material';


const ButtonSpinner = ({ className, size }) => (
  <div className={className}>
    <CircularProgress
      size={size}
      color="inherit"
    />
  </div>
);

ButtonSpinner.propTypes = {
  className: PropTypes.string.isRequired,
  size: PropTypes.number,
};

ButtonSpinner.defaultProps = {
  size: 24,
};


const styles = {
  root: {
    display: 'flex',
    marginLeft: '0.5rem',
    marginTop: '0.125rem',
    marginBottom: '0.125rem',
  },
};

const ButtonSpinner2 = withStyles(ButtonSpinner, styles);


export default ButtonSpinner2;
