import React from 'react';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { toast } from 'react-toastify';
import { Dialog, Button } from '@mui/material';

import { showEmbedSettingSelector } from 'app/redux/selectors';
import { isEmbed, isEmbeddable, getEmbedURL } from 'service/navigation';


const getHTML = (src) => `<iframe width="100%" height="100%" src="${src}" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen />`;


class EmbedButton extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showDialog: false,
      html: '',
    };
  }

  handleEmbedClick = () => {
    this.setState({
      showDialog: true,
      html: getHTML(getEmbedURL(window.location.href)),
    });
  };

  handleDialogClose = () => {
    this.setState({
      showDialog: false,
      html: '',
    });
  };

  handleCopyClick = () => {
    toast('Link copied to clipboard', { autoClose: 2000 });

    this.handleDialogClose();
  };

  render() {
    const { location, showEmbedSetting } = this.props;
    const { showDialog, html } = this.state;

    if (isEmbed()) return null;
    if (!isEmbeddable(location)) return null;
    if (!showEmbedSetting) return null;

    return (
      <>
        <div className="embed-button-wrapper">
          <button
            type="button"
            className="embed-button"
            onClick={this.handleEmbedClick}
          >
            <div className="embed-button-icon">
              <svg
                viewBox="0 0 36 36"
                preserveAspectRatio="xMidYMid meet"
                className="embed-button-svg"
              >
                <g>
                  <circle
                    cx="18"
                    cy="18"
                    r="17.5"
                    stroke="#888888"
                    fill="#F4F4F4"
                    strokeWidth="1"
                  />
                  <path
                    d="m21.41,23.29l-0.71,-0.71l4.59,-4.58l-4.59,-4.59l0.71,-0.71l5.3,5.3l-5.3,5.29zm-6.12,-0.7l-4.58,-4.59l4.59,-4.59l-0.71,-0.7l-5.3,5.29l5.29,5.29l0.71,-0.7z"
                    fill="#606060"
                  />
                </g>
              </svg>
            </div>
          </button>
        </div>
        <Dialog
          open={showDialog}
          onClose={this.handleDialogClose}
          maxWidth="sm"
          fullWidth
          classes={{ root: 'embed-button-dialog', paper: 'embed-button-dialog-paper' }}
        >
          <textarea
            readOnly
            className="html-textarea"
            value={html}
          />
          <div className="copy-button-row">
            <div className="copy-button-row-inner">
              <CopyToClipboard
                text={html}
                onCopy={this.handleCopyClick}
              >
                <Button
                  variant="outlined"
                  color="inherit"
                >
                  {'COPY'}
                </Button>
              </CopyToClipboard>
            </div>
          </div>
        </Dialog>
      </>
    );
  }
}

EmbedButton.propTypes = {
  location: PropTypes.object.isRequired,
  showEmbedSetting: PropTypes.bool.isRequired,
};


const mapStateToProps = (state) => ({
  showEmbedSetting: showEmbedSettingSelector(state),
});

const EmbedButton2 = connect(mapStateToProps)(EmbedButton);


export default withRouter(EmbedButton2);
