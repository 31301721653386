import React from 'react';
import { PropTypes } from 'prop-types';
import { withStyles } from 'tss-react/mui';
import { CircularProgress } from '@mui/material';


const Spinner = ({ className, size }) => (
  <div className={className}>
    <CircularProgress
      className="spinner"
      size={size}
    />
  </div>
);

Spinner.propTypes = {
  className: PropTypes.string.isRequired,
  size: PropTypes.number,
};

Spinner.defaultProps = {
  size: 40,
};


const styles = {
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    padding: '1.5rem',
  },
};

const Spinner2 = withStyles(Spinner, styles);


export default Spinner2;
