export const getNamespace = () => {
  const { host, protocol, hostname } = window.location;

  if (
    host.indexOf('localhost') > -1 ||
    protocol === 'file:' ||
    hostname.match(/^192\.168(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){2}$/) ||
    host.indexOf('bs-local.com') > -1
  ) {
    return 'alpha1';
  }

  const bearer = host.split('.')[0];

  return bearer;
};

export const getURLParam = (param) => {
  const urlParams = new URLSearchParams(window.location.search);

  return urlParams.get(param);
};

export const deleteURLParam = (param) => {
  const urlParams = new URLSearchParams(window.location.search);

  if (urlParams.has(param)) {
    urlParams.delete(param);
    const search = urlParams.toString();
    window.history.replaceState(null, null, `${window.location.pathname}${search ? `?${search}` : ''}`);
  }
};
