const HOME = '/';
const LOGIN = '/login';
const SIGNUP = '/signup';

const ASSIGNMENTS = '/assignments';
const ASSIGNMENT_NEW = `${ASSIGNMENTS}/new`;
const ASSIGNMENT_EDIT = `${ASSIGNMENTS}/:id/edit`;
const ASSIGNMENTS_TODO = `${ASSIGNMENTS}/todo`;
const ASSIGNMENTS_CLIMBS = `${ASSIGNMENTS}/climbs`;
const ASSIGNMENTS_TEARDOWN = `${ASSIGNMENTS}/teardown`;

const MAP = '/map';
const MAP_SEARCH = `${MAP}/search`;
const MAP_WALLS = `${MAP}/walls`;
const MAP_WALL_DETAIL = `${MAP}/wall/:wallId`;
const MAP_WALL_ZONE = `${MAP_WALL_DETAIL}/zone/:zoneId`;
const MAP_WALL_CLIMB = `${MAP_WALL_DETAIL}/climb/:climbId`;
const MAP_ZONE_CLIMB = `${MAP_WALL_ZONE}/climb/:climbId`;
const MAP_RECENT_CLIMBS = `${MAP}/recent/:climbType`;

const PROFILE = '/profile';
const PROFILE_EDIT = `${PROFILE}/edit`;

const STAFF = '/staff';
const STAFF_NEW = `${STAFF}/new`;
const STAFF_DETAIL = '/staff/:userAccountId';
const STAFF_ROUTE_STATS = `${STAFF_DETAIL}/stats/routes`;
const STAFF_BOULDERING_STATS = `${STAFF_DETAIL}/stats/bouldering`;

const REPORTS = '/reports';

const GYM_HEALTH = '/gym-health';

const LOCATIONS = '/locations';
const LOCATION_NEW = `${LOCATIONS}/new`;
const LOCATION_DETAIL = `${LOCATIONS}/:locationId`;
const LOCATION_EDIT = `${LOCATION_DETAIL}/edit`;
const LOCATION_MAP = `${LOCATION_DETAIL}/map`;
const LOCATION_SETTINGS = `${LOCATION_DETAIL}/settings`;
const LOCATION_SETTINGS_ANNOUNCEMENT = `${LOCATION_SETTINGS}/announcement`;
const LOCATION_GOALS = `${LOCATION_DETAIL}/goals`;
const LOCATION_SETTERS = `${LOCATION_DETAIL}/setters`;
const LOCATION_WALLS = `${LOCATION_DETAIL}/walls`;
const LOCATION_WALL_CREATE = `${LOCATION_WALLS}/new`;
const LOCATION_WALL = `${LOCATION_WALLS}/:wallId`;
const LOCATION_WALL_EDIT = `${LOCATION_WALL}/edit`;
const LOCATION_WALL_ZONES = `${LOCATION_WALL}/zones`;
const LOCATION_WALL_ZONE_CREATE = `${LOCATION_WALL_ZONES}/new`;
const LOCATION_WALL_ZONE = `${LOCATION_WALL_ZONES}/:zoneId`;
const LOCATION_WALL_ZONE_EDIT = `${LOCATION_WALL_ZONE}/edit`;

const SETTINGS = '/settings';
const COLORS = `${SETTINGS}/colors`;
const COLOR_NEW = `${COLORS}/new`;
const COLOR_EDIT = `${COLORS}/:colorId`;
const GOALS = `${SETTINGS}/goals`;
const GRADES = `${SETTINGS}/grades`;
const LABELS = `${SETTINGS}/labels`;
const ROLES = `${SETTINGS}/roles`;
const ROLE_NEW = `${ROLES}/new`;
const ROLE_EDIT = `${ROLES}/:roleId/edit`;
const ROTATION_GOALS = `${SETTINGS}/rotationGoals`;
const ROUTES = `${SETTINGS}/routes`;
const TAGS = `${SETTINGS}/tags`;
const TAG_NEW = `${TAGS}/new`;
const TAG_EDIT = `${TAGS}/:tagId`;
const SETTINGS_PORTAL = `${SETTINGS}/portal`;

/* eslint-disable object-property-newline, indent */
const NAVIGATION = {
  LOGIN, SIGNUP,
  HOME,
  ASSIGNMENTS,
    ASSIGNMENT_NEW, ASSIGNMENT_EDIT,
    ASSIGNMENTS_TODO, ASSIGNMENTS_CLIMBS, ASSIGNMENTS_TEARDOWN,
  MAP,
    MAP_SEARCH, MAP_WALLS, MAP_WALL_DETAIL, MAP_WALL_ZONE, MAP_WALL_CLIMB, MAP_ZONE_CLIMB, MAP_RECENT_CLIMBS,
  PROFILE, PROFILE_EDIT,
  STAFF,
    STAFF_NEW, STAFF_DETAIL, STAFF_ROUTE_STATS, STAFF_BOULDERING_STATS,
  REPORTS,
  GYM_HEALTH,
  LOCATIONS,
    LOCATION_NEW, LOCATION_DETAIL, LOCATION_EDIT, LOCATION_MAP,
    LOCATION_WALLS, LOCATION_WALL_CREATE, LOCATION_WALL, LOCATION_WALL_EDIT,
    LOCATION_WALL_ZONES, LOCATION_WALL_ZONE_CREATE, LOCATION_WALL_ZONE, LOCATION_WALL_ZONE_EDIT,
    LOCATION_SETTINGS, LOCATION_SETTINGS_ANNOUNCEMENT,
    LOCATION_GOALS, LOCATION_SETTERS,
  SETTINGS,
    ROLES, ROLE_NEW, ROLE_EDIT,
    ROTATION_GOALS, GOALS, GRADES,
    COLORS, COLOR_NEW, COLOR_EDIT,
    TAGS, TAG_NEW, TAG_EDIT,
    LABELS, ROUTES,
    SETTINGS_PORTAL,
};
/* eslint-enable object-property-newline, indent */

export default NAVIGATION;
