import { createReducer } from 'service/utility';

import { AUTH_LOGGED_IN_SUCCESS, AUTH_LOGGED_OUT_SUCCESS, SET_UNAUTHENTICATED_USER } from '../actions';


const initialState = {
  isLogged: false,
  uid: null,
  email: null,
  emailVerified: false,
};

const currentUser = createReducer(initialState, {
  [AUTH_LOGGED_IN_SUCCESS]: (state, { user }) => {
    console.log('Successfully finished login flow.');

    return {
      isLogged: true,
      uid: user.uid,
      email: user.email,
      emailVerified: user.emailVerified,
    };
  },
  [AUTH_LOGGED_OUT_SUCCESS]: (state) => {
    console.log('Successfully logged out of RSM.');

    return initialState;
  },
  [SET_UNAUTHENTICATED_USER]: (state) => initialState,
});

export default currentUser;
