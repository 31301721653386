import { initializeApp } from 'firebase/app';
import {
  getAuth, createUserWithEmailAndPassword, signInWithEmailAndPassword, signOut as fbSignOut,
  sendPasswordResetEmail as fbSendPasswordResetEmail,
} from 'firebase/auth';
import {
  getStorage, ref as fbStRef, uploadString, getDownloadURL,
  deleteObject as fbStDeleteObject,
} from 'firebase/storage';

import AppConfig from 'config/app';
import { getNamespace } from 'service/utility/urls';

////////

const getFirebaseApp = () => {
  const app = initializeApp(AppConfig.firebase);

  return app;
};

const firebaseApp = getFirebaseApp();

const getFirebaseStorage = (app) => {
  const storage = getStorage(app);

  return storage;
};

const firebaseStorage = getFirebaseStorage(firebaseApp);

////////

const signUp = async (email, password) => {
  const auth = getAuth();

  try {
    const userCredential = await createUserWithEmailAndPassword(auth, email, password);
    const { user } = userCredential;

    console.log('Firebase signup response: ', userCredential);

    return user;
  } catch (error) {
    const errorCode = error.code;
    const errorMessage = error.message;

    console.log('Firebase signup error, code ', errorCode, ', message: ', errorMessage);

    return null;
  }
};

const signIn = async (email, password) => {
  const auth = getAuth();

  try {
    console.log('Attempting to sign in Firebase');

    const userCredential = await signInWithEmailAndPassword(auth, email, password);
    const { user } = userCredential;

    console.log('Firebase sign in successful; response: ', userCredential);

    return user;
  } catch (error) {
    const errorMessage = `Firebase sign in error. Code: ${error.code}. Message: ${error.message}`;

    console.log(errorMessage);

    throw new Error(errorMessage);
  }
};

const signOut = async () => {
  const auth = getAuth();

  try {
    await fbSignOut(auth);

    console.log('Firebase logout successful');

    return true;
  } catch (error) {
    console.log('Firebase logout error: ', error);

    return false;
  }
};

const getIdToken = async () => {
  const auth = getAuth();
  const { currentUser } = auth;

  return currentUser ? currentUser.getIdToken() : null;
};

const sendPasswordResetEmail = async (email) => {
  try {
    const auth = getAuth();
    return await fbSendPasswordResetEmail(auth, email);
  } catch (error) {
    console.log('Password Reset Error: ', error);

    switch (error.code) {
      case 'auth/user-not-found':
        return 'User not found!';
      case 'auth/invalid-email':
        return 'Invalid email address format!';
      case 'auth/too-many-requests':
        return 'Too many attempts. Try again soon!';
      default:
        return 'Invalid attempt!';
    }
  }
};

////////

const uploadFile = async (path, fileName, body) => {
  const fileRef = fbStRef(
    firebaseStorage,
    `routesetting/namespace/${getNamespace()}/${path}/${fileName}`,
  );
  const snapshot = await uploadString(fileRef, body, 'data_url');

  return getDownloadURL(snapshot.ref);
};

const deleteFile = async (path, fileName) => {
  const fileRef = fbStRef(
    firebaseStorage,
    `routesetting/namespace/${getNamespace()}/${path}/${fileName}`,
  );

  await fbStDeleteObject(fileRef);
};

////////

const firebaseService = {
  app: firebaseApp,
  storage: firebaseStorage,
  signUp,
  signIn,
  signOut,
  getIdToken,
  sendPasswordResetEmail,
  uploadFile,
  deleteFile,
};

export default firebaseService;
