import { createReducer } from 'service/utility';

import { SET_INITIALIZATION_FINISHED } from '../actions';


const initialState = false;

const initializationFinished = createReducer(initialState, {
  [SET_INITIALIZATION_FINISHED]: () => {
    console.log('Finished initialization.');

    return true;
  },
});

export default initializationFinished;
