export const APP_NAME = 'Approach Route Setting';

const APP_CONFIG = {
  localhost: {
    apiRoot: 'https://localhost:3000',
    widgetsApiRoot: 'https://localhost:3000',
    widgetsApiKey: 'eVeRMVQZeD4DDDU0tdf9p5PfJzzFRE7j1cb110kw',
    firebase: {
      apiKey: 'AIzaSyD8XgnPxHMGf0KS2ZWjTo8Vxa61ysQmwHM',
      authDomain: 'approach-dev.firebaseapp.com',
      databaseURL: 'https://approach-dev.firebaseio.com',
      projectId: 'approach-dev',
      storageBucket: 'approach-dev.appspot.com',
      messagingSenderId: '476730786973',
    },
  },
  testing: {
    // apiRoot: 'https://rsv2.api.testing.tilefive.com',
    // apiRoot: 'https://73k5tgb30i.execute-api.us-east-1.amazonaws.com/',
    apiRoot: 'https://rs.api.testing.tilefive.com',
    widgetsApiRoot: 'https://widgets.api.testing.tilefive.com',
    widgetsApiKey: 'eVeRMVQZeD4DDDU0tdf9p5PfJzzFRE7j1cb110kw',
    firebase: {
      apiKey: 'AIzaSyD8XgnPxHMGf0KS2ZWjTo8Vxa61ysQmwHM',
      authDomain: 'approach-dev.firebaseapp.com',
      databaseURL: 'https://approach-dev.firebaseio.com',
      projectId: 'approach-dev',
      storageBucket: 'approach-dev.appspot.com',
      messagingSenderId: '476730786973',
    },
  },
  staging: {
    apiRoot: 'https://rs.api.staging.tilefive.com',
    widgetsApiRoot: 'https://widgets.api.staging.tilefive.com',
    widgetsApiKey: 'fsHlriEoQY8oxluJLm3vp3nERawSTRQYapppB5mN',
    firebase: {
      apiKey: 'AIzaSyBkVhB9zG7OgFZUjBZB5tJJ-LLTcxZYYsI',
      authDomain: 'approach-stage.firebaseapp.com',
      databaseURL: 'https://approach-stage.firebaseio.com',
      projectId: 'approach-stage',
      storageBucket: 'approach-stage.appspot.com',
      messagingSenderId: '257264323242',
    },
  },
  production: {
    apiRoot: 'https://rs.api.prod.tilefive.com',
    widgetsApiRoot: 'https://widgets.api.prod.tilefive.com',
    widgetsApiKey: 'OQ2z4Q3jSU1BW3y9dyfEW5FlEFu1ozIj7jE27qjy',
    firebase: {
      apiKey: 'AIzaSyAbHcdTCEcSekzXcCGtoC3rXOL_N37VyFI',
      authDomain: 'approach-production.firebaseapp.com',
      databaseURL: 'https://approach-production.firebaseio.com',
      projectId: 'approach-production',
      storageBucket: 'approach-production.appspot.com',
      messagingSenderId: '414043972075',
    },
  },
};

const getAppConfig = () => {
  const env = process.env.REACT_APP_ENV || process.env.NODE_ENV;

  if (env === 'production') return APP_CONFIG.production;
  if (env === 'staging') return APP_CONFIG.staging;
  if (env === 'testing' || env === 'development') return APP_CONFIG.testing;

  return APP_CONFIG.testing;
};

const AppConfig = getAppConfig();

export default AppConfig;
