import { ROLES } from 'config/staffRoles';


const StaffService = {
  getStaffFromUserAccount: (userAccount) => ({
    ...userAccount.user,
    userAccountId: userAccount.id,
    role: userAccount.role,
  }),

  setterLevelDisplay: (level) => (
    level
      ? `Level ${level}`
      : ''
  ),

  roleDisplay: (role) => {
    switch (role) {
      case ROLES.ADMIN:
        return 'Admin';
      case ROLES.SETTER:
        return 'Setter';
      case ROLES.STRIPPER:
        return 'Stripper';
      default:
        return '';
    }
  },
};


export default StaffService;
