import React from 'react';
import { PropTypes } from 'prop-types';
import classnames from 'classnames';


const StatusOverlay = ({ fixed, children, backdrop }) => (
  <div className={classnames('status-overlay', { fixed, backdrop })}>
    {children}
  </div>
);

StatusOverlay.propTypes = {
  backdrop: PropTypes.bool,
  children: PropTypes.any,
  fixed: PropTypes.bool,
};

StatusOverlay.defaultProps = {
  backdrop: false,
  fixed: false,
};


export default StatusOverlay;
