import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';

import { loadState } from 'service/storage';

import rootReducer from './reducers';
import { initSagas } from './initSagas';


const getStore = () => {
  const initialState = loadState();
  const sagaMiddleware = createSagaMiddleware();
  const middleWares = [sagaMiddleware];
  const composables = [applyMiddleware(...middleWares)];
  const enhancer = compose(...composables);

  const store = createStore(
    rootReducer,
    initialState,
    enhancer,
  );

  initSagas(sagaMiddleware);

  return store;
};

const AppStore = getStore();

export default AppStore;
