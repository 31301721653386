import { APP_NAME } from 'config/app';

const getKeyName = (key) => `@${APP_NAME}_${key}`;

const stateKey = getKeyName('state');


export const loadState = () => {
  const serializedState = localStorage.getItem(stateKey);
  const state = serializedState ? JSON.parse(serializedState) : {};

  return state;
};

export const saveState = (state) => {
  const serializedState = JSON.stringify({
    currentAccount: {
      id: state.currentAccount?.id ?? null,
      namespace: state.currentAccount?.namespace ?? null,
    },
    currentLocation: {
      id: state.currentLocation?.id ?? null,
    },
    currentUser: {
      uid: state.currentUser.uid,
      email: state.currentUser.email,
      emailVerified: state.currentUser.emailVerified,
    },
  });

  localStorage.setItem(stateKey, serializedState);
};
