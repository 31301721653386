const MODIFIER_PLUS = '+';
const MODIFIER_MINUS = '-';

const GradesService = {
  getGradeNumber: (grade) => grade.replace(/[^0-9.]/g, ''),

  getGradePrimaryNumber: (grade) => {
    const match = grade.match(/[\d]+/g);

    if (!match) return null;

    return Number(match[0]);
  },

  getGradeSecondaryNumber: (grade) => {
    const match = grade.match(/[\d]+/g);

    if (!match) return null;

    // "5.12" => 12
    // 19/22 => 22
    const value = Number(match[1]);

    // return null for integers;
    if (isNaN(value)) return null;

    return value;
  },

  getGradeLetter: (grade) => {
    const match = grade.toUpperCase()
      .replace('V', '') // replace redundant Vscale indicators
      .match(/[A-Z]/);

    if (!match) return null;

    return match[0];
  },

  getGradeModifier: (grade) => {
    if (grade.includes(MODIFIER_PLUS)) return MODIFIER_PLUS;
    if (grade.includes(MODIFIER_MINUS)) return MODIFIER_MINUS;
    return null;
  },

  getSortedGrades: (grades) => grades.sort((a, b) => {
    // VB is a special Vscale grade which always comes first
    if (a.toUpperCase() === 'VB') return -1;

    // First sort major grade numbers
    const aMajorNumber = GradesService.getGradePrimaryNumber(a);
    const bMajorNumber = GradesService.getGradePrimaryNumber(b);

    if (aMajorNumber > bMajorNumber) return 1;
    if (aMajorNumber < bMajorNumber) return -1;

    // Next sort by minor grade numbers
    const aMinorNumber = GradesService.getGradeSecondaryNumber(a);
    const bMinorNumber = GradesService.getGradeSecondaryNumber(b);

    if (aMinorNumber > bMinorNumber) return 1;
    if (aMinorNumber < bMinorNumber) return -1;

    // Now letters a,b,c,d
    const aLetter = GradesService.getGradeLetter(a);
    const bLetter = GradesService.getGradeLetter(b);

    if (aLetter > bLetter) return 1;
    if (aLetter < bLetter) return -1;

    // And finally by modifiers like +,-
    const aModifier = GradesService.getGradeModifier(a);

    if (!aModifier) return -1;
    if (aModifier === MODIFIER_MINUS) return -1;

    // assume that + modifier sorts later
    return 1;
  }),

  getGradeOptions: (grades) => grades.map((g) => ({
    value: g,
    label: g,
  })),
};

export default GradesService;
