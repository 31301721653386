import { take, put, call } from 'redux-saga/effects';

import Api from 'service/api';
import { isEmbed } from 'service/navigation';

import {
  SET_UNAUTHENTICATED_USER, setEmbedNamespace,
  setCurrentAccountAction, changeLocationAction,
  setInitializationFinishedAction, setInitializationErrorAction,
} from '../actions';


export function *unauthenticatedSaga() {
  while (true) {
    const { history } = yield take(SET_UNAUTHENTICATED_USER);

    if (isEmbed()) {
      const urlParams = new URLSearchParams(window.location.search);
      const namespace = urlParams.get('_namespace');
      const locationId = urlParams.get('_locationId');
      urlParams.delete('_namespace');
      urlParams.delete('_locationId');
      const search = urlParams.toString();
      history.replace(`${window.location.pathname}${search ? `?${search}` : ''}`);

      if (namespace) {
        yield put(setEmbedNamespace(namespace));
        const account = yield call(Api.accounts.get, namespace);
        if (account) {
          yield put(setCurrentAccountAction(account));
        } else {
          yield put(setInitializationErrorAction('Invalid account'));
          yield put(setInitializationFinishedAction());
          return null;
        }
      } else {
        yield put(setInitializationErrorAction('No namespace provided'));
        yield put(setInitializationFinishedAction());
        return null;
      }

      if (locationId) {
        const location = yield call(Api.locations.get, namespace, locationId);
        if (location) {
          yield put(changeLocationAction(location));
        } else {
          yield put(setInitializationErrorAction('Invalid location'));
          yield put(setInitializationFinishedAction());
          return null;
        }
      } else {
        yield put(setInitializationErrorAction('No location provided'));
        yield put(setInitializationFinishedAction());
        return null;
      }
    }

    yield put(setInitializationFinishedAction());
  }
}
