import { makeActionCreator } from 'service/utility';


export const SW_INIT = 'SW_INIT';
export const swInitAction = makeActionCreator(SW_INIT);

export const SW_UPDATE = 'SW_UPDATE';
export const swUpdateAction = makeActionCreator(SW_UPDATE, 'payload');


export const SIGN_IN = 'SIGN_IN';
export const signInAction = makeActionCreator(SIGN_IN, 'user');

export const SIGN_OUT = 'SIGN_OUT';
export const signOutAction = makeActionCreator(SIGN_OUT);

export const AUTH_LOGGED_IN_SUCCESS = 'AUTH_LOGGED_IN_SUCCESS';
export const authLoggedInSuccess = makeActionCreator(AUTH_LOGGED_IN_SUCCESS, 'user');

export const AUTH_LOGGED_OUT_SUCCESS = 'AUTH_LOGGED_OUT_SUCCESS';
export const authLoggedOutSuccess = makeActionCreator(AUTH_LOGGED_OUT_SUCCESS);

export const SET_UNAUTHENTICATED_USER = 'SET_UNAUTHENTICATED_USER';
export const setUnauthenticatedUserAction = makeActionCreator(SET_UNAUTHENTICATED_USER, 'history');

export const SET_INITIALIZATION_FINISHED = 'SET_INITIALIZATION_FINISHED';
export const setInitializationFinishedAction = makeActionCreator(SET_INITIALIZATION_FINISHED);

export const SET_INITIALIZATION_ERROR = 'SET_INITIALIZATION_ERROR';
export const setInitializationErrorAction = makeActionCreator(SET_INITIALIZATION_ERROR, 'error');


export const CHANGE_ACCOUNT = 'CHANGE_ACCOUNT';
export const changeAccountAction = makeActionCreator(CHANGE_ACCOUNT, 'account');

export const SET_CURRENT_ACCOUNT = 'SET_CURRENT_ACCOUNT';
export const setCurrentAccountAction = makeActionCreator(SET_CURRENT_ACCOUNT, 'account');

export const CHANGE_LOCATION = 'CHANGE_LOCATION';
export const changeLocationAction = makeActionCreator(CHANGE_LOCATION, 'location');

export const SET_CURRENT_LOCATION = 'SET_CURRENT_LOCATION';
export const setCurrentLocationAction = makeActionCreator(SET_CURRENT_LOCATION, 'location');

export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const setCurrentUser = makeActionCreator(SET_CURRENT_USER, 'user');

export const SET_CURRENT_STAFF = 'SET_CURRENT_STAFF';
export const setCurrentStaff = makeActionCreator(SET_CURRENT_STAFF, 'staff');

export const SET_LANDING_PAGE = 'SET_LANDING_PAGE';
export const setLandingPageAction = makeActionCreator(SET_LANDING_PAGE, 'location');

export const SET_EMBED_NAMESPACE = 'SET_EMBED_NAMESPACE';
export const setEmbedNamespace = makeActionCreator(SET_EMBED_NAMESPACE, 'namespace');
