import React from 'react';
import { SvgIcon } from '@mui/material';

const InProgressIcon = (props) => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <path d="M10.9998 0V2C15.3898 2.54 18.4998 6.53 17.9598 10.92C17.7391 12.689 16.9348 14.3339 15.6742 15.5944C14.4137 16.855 12.7688 17.6593 10.9998 17.88V19.88C16.4998 19.28 20.4498 14.34 19.8498 8.85C19.3298 4.19 15.6598 0.5 10.9998 0ZM8.9998 0C7.0398 0.18 5.18981 0.95 3.6698 2.2L5.0998 3.74C6.2198 2.84 7.5698 2.26 8.9998 2.06V0.0599999V0ZM2.2598 3.67C1.00548 5.18495 0.235504 7.04196 0.0498047 9H2.0498C2.2398 7.58 2.7998 6.23 3.6898 5.1L2.2598 3.67ZM0.0598047 11C0.259805 12.96 1.0298 14.81 2.2698 16.33L3.6898 14.9C2.80673 13.7696 2.24376 12.4226 2.0598 11H0.0598047ZM5.0598 16.37L3.6698 17.74C5.18458 19.0027 7.03907 19.789 8.9998 20V18C7.57721 17.816 6.23021 17.2531 5.0998 16.37H5.0598ZM10.9998 11V5H8.9998V11H10.9998ZM10.9998 15V13H8.9998V15H10.9998Z" />
  </SvgIcon>
);

export default InProgressIcon;
