import clm from 'country-locale-map';
import { DateTime } from 'luxon';

import { DEFAULT_COUNTRY_CODE } from 'config/defaults';


export const getLocationCountry = (location) => (
  (location?.countryCode ?? DEFAULT_COUNTRY_CODE).toUpperCase()
);

export const getLocationLocale = (location) => (
  clm.getLocaleByAlpha2(getLocationCountry(location)).replace(/_/, '-')
);

export const getLocationTimezone = (location) => location?.timeZone || DateTime.local().zoneName;
