import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import './config/i18n';
import './styles/root.scss';
import './styles/airbnbDatepicker.scss';
import 'react-datepicker/dist/react-datepicker.css';

import { SW_INIT, SW_UPDATE } from './app/redux/actions';
import store from './app/redux/store';
import { getLocationLocale } from './service/i18n';
import { saveState } from './service/storage';
import { setNewLocale } from './service/utility';
import * as serviceWorker from './serviceWorkerRegistration';

import App from './app';


const initialState = store.getState();

if (initialState.app && initialState.app.currentLocation) {
  setNewLocale(getLocationLocale(initialState.app.currentLocation));
}

store.subscribe(() => {
  saveState(store.getState());
});


ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root'),
);


serviceWorker.register({
  onSuccess: () => store.dispatch({ type: SW_INIT }),
  onUpdate: (registration) => store.dispatch({ type: SW_UPDATE, payload: registration }),
});
