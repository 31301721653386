import { BoulderingIcon, RopeIcon } from 'assets/icons';


export const CLIMB_TYPES = {
  ROUTES: 'ROUTES',
  BOULDERING: 'BOULDERING',
};

export const CLIMB_TYPE_VALUES = [
  CLIMB_TYPES.ROUTES,
  CLIMB_TYPES.BOULDERING,
];

export const CLIMB_TYPE_LITERALS = {
  [CLIMB_TYPES.ROUTES]: 'route',
  [CLIMB_TYPES.BOULDERING]: 'boulder',
};

export const CLIMB_TYPE_LABELS = {
  [CLIMB_TYPES.ROUTES]: 'Routes',
  [CLIMB_TYPES.BOULDERING]: 'Boulders',
};

export const CLIMB_TYPE_ICONS = {
  [CLIMB_TYPES.ROUTES]: RopeIcon,
  [CLIMB_TYPES.BOULDERING]: BoulderingIcon,
};

export const CLIMB_TYPE_OPTIONS = CLIMB_TYPE_VALUES.map((climbType) => ({
  value: climbType,
  label: CLIMB_TYPE_LABELS[climbType],
  icon: CLIMB_TYPE_ICONS[climbType],
}));

export const ROUTE_TYPES = {
  TOP_ROPE: 'TOP_ROPE',
  AUTO_BELAY: 'AUTO_BELAY',
  LEAD: 'LEAD',
};

export const ROUTE_TYPE_LABELS = {
  TOP_ROPE: 'Top Rope',
  AUTO_BELAY: 'Auto-Belay',
  LEAD: 'Lead',
};

export const ROUTE_TYPE_VALUES = [
  ROUTE_TYPES.TOP_ROPE,
  ROUTE_TYPES.AUTO_BELAY,
  ROUTE_TYPES.LEAD,
];

export const ROUTE_TYPE_OPTIONS = ROUTE_TYPE_VALUES.map((routeType) => ({
  value: routeType,
  label: ROUTE_TYPE_LABELS[routeType],
}));

export const RECENT_DAYS = 7;
