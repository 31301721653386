import _ from 'lodash';

import { isAuthenticatedUserSelector } from 'app/redux/selectors';
import store from 'app/redux/store';
import { initAxios, initAxiosWidgets } from 'service/axios';
import AssignmentService from 'service/AssignmentService';
import StaffService from 'service/StaffService';


const getAccount = (namespace, id) => {
  const state = store.getState();
  const isAuthenticatedUser = isAuthenticatedUserSelector(state);

  if (isAuthenticatedUser) {
    return initAxios().then((axios) => axios.get(`/${namespace}/accounts/${id}/`));
  }

  return initAxiosWidgets(namespace).then((axios) => axios.get('/routesetting/account'));
};

const getLocation = (namespace, id) => {
  const state = store.getState();
  const isAuthenticatedUser = isAuthenticatedUserSelector(state);

  if (isAuthenticatedUser) {
    return initAxios().then((axios) => axios.get(`/${namespace}/locations/${id}/`));
  }

  return initAxiosWidgets(namespace).then((axios) => axios.get(`/routesetting/location/${id}`));
};

const getLocationPoly = (namespace, id) => {
  const state = store.getState();
  const isAuthenticatedUser = isAuthenticatedUserSelector(state);

  if (isAuthenticatedUser) {
    return initAxios().then((axios) => axios.get(`/${namespace}/draw/${id}/`));
  }

  return initAxiosWidgets(namespace).then((axios) => axios.get(`/routesetting/draw/${id}`));
};

const queryClimbs = (namespace, query) => {
  const state = store.getState();
  const isAuthenticatedUser = isAuthenticatedUserSelector(state);

  if (isAuthenticatedUser) {
    return initAxios().then((axios) => axios.get(`/${namespace}/climbs?${query}`));
  }

  return initAxiosWidgets(namespace).then((axios) => axios.get(`/routesetting/climb?${query}`));
};

const getWalls = (namespace) => {
  const state = store.getState();
  const isAuthenticatedUser = isAuthenticatedUserSelector(state);

  if (isAuthenticatedUser) {
    return initAxios().then((axios) => axios.get(`/${namespace}/walls/`));
  }

  return initAxiosWidgets(namespace).then((axios) => axios.get('/routesetting/wall'));
};


const Api = {
  accounts: {
    get: (namespace, id) => getAccount(namespace, id).then(
      (r) => r.data
    ),
    upsert: (namespace, data) => initAxios().then(
      (axios) => axios.put(`/${namespace}/accounts/`, data).then((r) => r.data)
    ),
    upsertSettings: (namespace, data) => initAxios().then(
      (axios) => axios.put(`/${namespace}/accounts/settings/`, data).then((r) => r.data)
    ),
  },
  locations: {
    get: (namespace, id) => getLocation(namespace, id).then(
      (r) => r.data
    ),
    query: (namespace) => initAxios().then(
      (axios) => axios.get(`/${namespace}/locations/`).then((r) => r.data)
    ),
    upsert: (namespace, data) => initAxios().then(
      (axios) => axios.put(`/${namespace}/locations/`, data).then((r) => r.data)
    ),
    delete: (namespace, id) => initAxios().then(
      (axios) => axios.delete(`/${namespace}/locations/${id}/`).then((r) => r.data)
    ),
  },
  locationPoly: {
    create: (namespace, data) => initAxios().then(
      (axios) => axios.post(`/${namespace}/draw/`, data).then((r) => r.data)
    ),
    get: (namespace, id) => getLocationPoly(namespace, id).then(
      (r) => r.data
    ),
    upsert: (namespace, data) => initAxios().then(
      (axios) => axios.put(`/${namespace}/draw/`, data).then((r) => r.data)
    ),
    getWalls: (namespace, locationPolyId) => initAxios().then(
      (axios) => axios.get(`/${namespace}/draw/${locationPolyId}/walls/`).then((r) => r.data)
    ),
    delete: (namespace, locationPolyId) => initAxios().then(
      (axios) => axios.delete(`/${namespace}/draw/${locationPolyId}/`).then((r) => r.data)
    ),
  },
  climbs: {
    get: (namespace, id) => initAxios().then(
      (axios) => axios.get(`/${namespace}/assignments/${id}/`).then((r) => AssignmentService.flatRouteTypes(r.data))
    ),
    query: (namespace, query = '') => queryClimbs(namespace, query).then(
      (r) => r.data
    ),
    upsert: (namespace, data) => initAxios().then(
      (axios) => axios.put(`/${namespace}/climbs/`, data).then((r) => AssignmentService.flatRouteTypes(r.data))
    ),
    setTags: (namespace, id, data) => initAxios().then(
      (axios) => axios.put(`/${namespace}/assignments/${id}/tags/`, data).then((r) => r.data)
    ),
    uploadMedia: (namespace, id, imageURLs) => initAxios().then(
      (axios) => axios.post(`/${namespace}/climbs/${id}/media/`, imageURLs).then((r) => r.data)
    ),
    deleteMedia: (namespace, climbId, mediaId) => initAxios().then(
      (axios) => axios.delete(`/${namespace}/climbs/${climbId}/media/${mediaId}`).then((r) => r.data)
    ),
    massUpdateStatus: (namespace, climbIds, status) => initAxios().then(
      (axios) => axios.put(`/${namespace}/climbs/status/`, { climbIds, status }).then((r) => r.data)
    ),
  },
  staff: {
    invite: (namespace, data) => initAxios().then(
      (axios) => axios.post(`/${namespace}/accounts/users/invite/`, data).then((r) => (
        StaffService.getStaffFromUserAccount(r.data)
      ))
    ),
    getIsUserVerified: (email) => initAxios().then(
      (axios) => axios.get(`/profile/verified/${email}/`).then((r) => r.data)
    ),
    get: (namespace, id) => initAxios().then(
      (axios) => axios.get(`/${namespace}/accounts/users/${id}/`).then((r) => (
        StaffService.getStaffFromUserAccount(r.data)
      ))
    ),
    query: (namespace) => initAxios().then(
      (axios) => axios.get(`/${namespace}/accounts/users/`).then((r) => (
        r.data.map((e) => StaffService.getStaffFromUserAccount(e))
      ))
    ),
    upsert: (namespace, id, data) => initAxios().then(
      (axios) => axios.put(`/${namespace}/accounts/users/${id}`, data).then((r) => r.data)
    ),
    delete: (namespace, id) => initAxios().then(
      (axios) => axios.delete(`/${namespace}/accounts/users/${id}/`).then((r) => r.data)
    ),
  },
  profile: {
    get: () => initAxios().then(
      (axios) => axios.get('/my/profile').then((r) => r.data)
    ),
    update: (data) => initAxios().then(
      (axios) => axios.put('/my/profile', data).then((r) => r.data)
    ),
  },
  wallPoly: {
    create: (namespace, locationPolyId, data) => initAxios().then(
      (axios) => axios.post(`/${namespace}/draw/${locationPolyId}/walls/`, data).then((r) => r.data)
    ),
    get: (namespace, locationPolyId, wallPolyId) => initAxios().then(
      (axios) => axios.get(`/${namespace}/draw/${locationPolyId}/walls/${wallPolyId}/`).then((r) => r.data)
    ),
    upsert: (namespace, locationPolyId, data) => initAxios().then(
      (axios) => axios.put(`/${namespace}/draw/${locationPolyId}/walls/`, data).then((r) => r.data)
    ),
    getZones: (namespace, locationPolyId, wallPolyId) => initAxios().then(
      (axios) => axios.get(`/${namespace}/draw/${locationPolyId}/walls/${wallPolyId}/zones/`).then((r) => r.data)
    ),
    delete: (namespace, locationPolyId, wallPolyId) => initAxios().then(
      (axios) => axios.delete(`/${namespace}/draw/${locationPolyId}/walls/${wallPolyId}/`).then((r) => r.data)
    ),
  },
  zonePoly: {
    create: (namespace, locationPolyId, wallPolyId, data) => initAxios().then(
      (axios) => axios.post(`/${namespace}/draw/${locationPolyId}/walls/${wallPolyId}/zones/`, data).then((r) => r.data)
    ),
    get: (namespace, locationPolyId, wallPolyId, zonePolyId) => initAxios().then(
      (axios) => axios.get(`/${namespace}/draw/${locationPolyId}/walls/${wallPolyId}/zones/${zonePolyId}/`).then((r) => r.data)
    ),
    upsert: (namespace, locationPolyId, wallPolyId, data) => initAxios().then(
      (axios) => axios.put(`/${namespace}/draw/${locationPolyId}/walls/${wallPolyId}/zones/`, data).then((r) => r.data)
    ),
    delete: (namespace, locationPolyId, wallPolyId, zonePolyId) => initAxios().then(
      (axios) => axios.delete(`/${namespace}/draw/${locationPolyId}/walls/${wallPolyId}/zones/${zonePolyId}/`).then((r) => r.data)
    ),
  },
  climbPoly: {
    createForWall: (namespace, locationPolyId, wallPolyId, data) => initAxios().then(
      (axios) => axios.post(`/${namespace}/draw/${locationPolyId}/walls/${wallPolyId}/climbs`, data).then((r) => r.data)
    ),
    createForZone: (namespace, locationPolyId, wallPolyId, zonePolyId, data) => initAxios().then(
      (axios) => axios.post(`/${namespace}/draw/${locationPolyId}/walls/${wallPolyId}/zones/${zonePolyId}/climbs`, data).then((r) => r.data)
    ),
  },
  walls: {
    get: (namespace, id) => initAxios().then(
      (axios) => axios.get(`/${namespace}/walls/${id}/`).then((r) => r.data)
    ),
    query: (namespace, locationId) => getWalls(namespace).then(
      (r) => (r.data || []).filter((w) => w.locationId === locationId)
    ),
    upsert: (namespace, data) => initAxios().then(
      (axios) => axios.put(`/${namespace}/walls/`, data).then((r) => r.data)
    ),
    delete: (namespace, id) => initAxios().then(
      (axios) => axios.delete(`/${namespace}/walls/${id}/`).then((r) => r.data)
    ),
  },
  zones: {
    get: (namespace, wallId, zoneId) => initAxios().then(
      (axios) => axios.get(`/${namespace}/walls/${wallId}/zones/${zoneId}/`).then((r) => r.data)
    ),
    query: (namespace, wallId) => initAxios().then(
      (axios) => axios.get(`/${namespace}/walls/${wallId}/zones/`).then((r) => r.data)
    ),
    upsert: (namespace, wallId, data) => initAxios().then(
      (axios) => axios.put(`/${namespace}/walls/${wallId}/zones/`, data).then((r) => r.data)
    ),
    delete: (namespace, wallId, zoneId) => initAxios().then(
      (axios) => axios.delete(`/${namespace}/walls/${wallId}/zones/${zoneId}/`).then((r) => r.data)
    ),
  },
  colors: {
    get: (namespace, id) => initAxios().then(
      (axios) => axios.get(`/${namespace}/options/colors/${id}/`).then((r) => r.data)
    ),
    query: (namespace) => initAxios().then(
      (axios) => axios.get(`/${namespace}/options/colors/`).then((r) => r.data)
    ),
    upsert: (namespace, data) => initAxios().then(
      (axios) => axios.put(`/${namespace}/options/colors/`, data).then((r) => r.data)
    ),
    delete: (namespace, id) => initAxios().then(
      (axios) => axios.delete(`/${namespace}/options/colors/${id}/`).then((r) => r.data)
    ),
  },
  tags: {
    get: (namespace, id) => initAxios().then(
      (axios) => axios.get(`/${namespace}/options/tags/${id}/`).then((r) => r.data)
    ),
    query: (namespace) => initAxios().then(
      (axios) => axios.get(`/${namespace}/options/tags/`).then((r) => r.data)
    ),
    upsert: (namespace, data) => initAxios().then(
      (axios) => axios.put(`/${namespace}/options/tags/`, data).then((r) => r.data)
    ),
    delete: (namespace, id) => initAxios().then(
      (axios) => axios.delete(`/${namespace}/options/tags/${id}/`).then((r) => r.data)
    ),
  },
  /**
   * Query and convert database models a Scale pattern
   *
   * @example
   *   {scale: 'FRENCH', grades: []}
   *
   * TODO: should probably have a boulderScales API instead
   */
  routeScales: {
    query: (namespace) => initAxios().then(
      (axios) => axios.get(`/${namespace}/options/routeGrades/`).then(({ data }) => (
        _(_.groupBy(data, 'scale'))
          .map((value, key) => ({
            scale: key,
            grades: value.map((v) => v.grade),
          }))
          .keyBy('scale')
          .value()
      ))
    ),
  },
  /**
   * Query and convert database models a Scale pattern
   *
   * @example
   *   {scale: 'FRENCH', grades: []}
   *
   * TODO: should probably have a boulderScales API instead
   */
  boulderScales: {
    query: (namespace) => initAxios().then(
      (axios) => axios.get(`/${namespace}/options/boulderGrades/`).then(({ data }) => (
        _(_.groupBy(data, 'scale'))
          .map((value, key) => ({
            scale: key,
            grades: value.map((v) => v.grade),
          }))
          .keyBy('scale')
          .value()
      ))
    ),
  },
};


export default Api;
