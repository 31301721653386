import React from 'react';
import { SvgIcon } from '@mui/material';

const RopeIcon = (props) => (
  <SvgIcon viewBox="0 0 512 512" {...props}>
    <path d="M161.34,278.955c-21.06,15.044-41.992,23.748-60.459,31.437c-17.442,7.253-32.495,13.517-42.274,23.296
      c-32.998,32.998-32.998,86.69,0,119.68c16.503,16.504,38.178,24.755,59.853,24.755c21.675,0,43.349-8.252,59.844-24.755
      c9.771-9.762,16.034-24.823,23.296-42.266c7.68-18.466,16.393-39.39,31.428-60.45c2.739-3.831,1.843-9.156-1.988-11.904
      c-3.831-2.731-9.148-1.843-11.896,1.988c-16.171,22.63-25.668,45.466-33.306,63.812c-6.554,15.761-12.22,29.38-19.601,36.753
      c-26.342,26.334-69.214,26.342-95.565,0c-26.342-26.334-26.342-69.205,0-95.548c7.381-7.39,21-13.047,36.77-19.61
      c18.347-7.629,41.182-17.126,63.812-33.297c3.84-2.739,4.727-8.073,1.988-11.904C170.505,277.103,165.171,276.215,161.34,278.955
      z"
    />
    <path d="M130.185,351.352c12.331-4.48,25.079-9.114,35.26-16.777c3.763-2.833,4.514-8.192,1.681-11.955
      c-2.825-3.755-8.175-4.523-11.947-1.681c-8.141,6.127-19.166,10.129-30.831,14.37c-15.078,5.478-30.677,11.153-41.813,22.289
      c-9.6,9.6-14.891,22.357-14.891,35.934c0,13.568,5.291,26.325,14.891,35.925c9.591,9.6,22.349,14.882,35.925,14.882
      s26.334-5.282,35.925-14.882c9.779-9.771,16.051-24.841,23.305-42.291c7.689-18.458,16.384-39.381,31.42-60.433
      c2.739-3.831,1.852-9.165-1.988-11.904c-3.831-2.731-9.148-1.852-11.896,1.988c-16.162,22.622-25.66,45.449-33.289,63.795
      c-6.562,15.77-12.228,29.389-19.618,36.779c-12.749,12.749-34.97,12.749-47.718,0c-13.158-13.158-13.158-34.569,0-47.727
      C103.057,361.207,116.233,356.42,130.185,351.352z"
    />
    <path d="M291.908,351.625L160.367,220.083c-3.337-3.337-8.738-3.337-12.066,0c-3.336,3.328-3.336,8.73,0,12.066L280.03,363.87
      c0.512,0.486,50.748,48.725,27.861,102.127c-1.86,4.335,0.154,9.353,4.48,11.204c1.092,0.469,2.236,0.691,3.362,0.691
      c3.311,0,6.46-1.937,7.842-5.171C351.215,408.226,294.153,353.715,291.908,351.625z"
    />
    <path d="M254.967,362.667c-3.823-2.748-9.156-1.852-11.904,1.988c-15.292,21.402-22.989,41.079-29.781,58.445
      c-6.545,16.751-12.211,31.206-23.125,42.129c-39.543,39.526-103.851,39.526-143.394,0
      c-19.149-19.149-29.696-44.612-29.696-71.697c0-27.085,10.547-52.548,29.696-71.697c10.923-10.923,25.387-16.58,42.138-23.134
      c17.357-6.784,37.035-14.481,58.436-29.764c3.831-2.748,4.719-8.073,1.98-11.904c-2.739-3.84-8.073-4.719-11.904-1.988
      c-19.669,14.054-38.298,21.333-54.724,27.767c-17.775,6.946-34.543,13.508-47.991,26.957C12.322,332.134,0,361.882,0,393.532
      c0,31.642,12.322,61.389,34.697,83.763c23.091,23.091,53.427,34.637,83.763,34.637c30.328,0,60.672-11.546,83.763-34.637
      c13.44-13.44,20.002-30.217,26.948-47.974c6.434-16.444,13.722-35.072,27.776-54.75
      C259.695,370.731,258.799,365.406,254.967,362.667z"
    />
    <path d="M303.761,339.763c1.664,1.673,3.849,2.5,6.033,2.5c2.185,0,4.361-0.828,6.033-2.5c3.328-3.328,3.328-8.73,0-12.066
      L184.286,196.156c-3.337-3.328-8.738-3.328-12.066,0c-3.336,3.337-3.336,8.738,0,12.066L303.761,339.763z"
    />
    <path d="M345.677,234.65c1.715,0,3.447-0.521,4.949-1.596c21.06-15.044,41.992-23.748,60.45-31.428
      c17.442-7.262,32.495-13.517,42.274-23.296c32.99-32.998,32.99-86.69,0-119.689c-15.991-15.991-37.239-24.798-59.853-24.798
      c-22.605,0-43.853,8.806-59.836,24.798c-9.771,9.771-16.043,24.832-23.296,42.266c-7.688,18.466-16.401,39.39-31.437,60.45
      c-2.739,3.831-1.843,9.165,1.988,11.904c3.831,2.748,9.156,1.852,11.895-1.988c16.171-22.622,25.668-45.457,33.306-63.804
      c6.562-15.77,12.228-29.38,19.61-36.762c12.757-12.766,29.722-19.797,47.77-19.797c18.057,0,35.021,7.031,47.787,19.797
      c26.342,26.342,26.342,69.214,0,95.556c-7.381,7.381-21.001,13.047-36.762,19.601c-18.347,7.637-41.182,17.135-63.812,33.306
      c-3.84,2.739-4.727,8.064-1.988,11.904C340.395,233.404,343.006,234.65,345.677,234.65z"
    />
    <path d="M477.261,34.722c-22.366-22.374-52.122-34.697-83.763-34.697s-61.389,12.322-83.763,34.697
      c-13.432,13.44-19.994,30.208-26.94,47.966c-6.426,16.435-13.722,35.072-27.776,54.758c-2.748,3.831-1.852,9.156,1.98,11.895
      c3.831,2.756,9.156,1.852,11.904-1.98c15.3-21.41,22.989-41.097,29.79-58.462c6.537-16.742,12.203-31.198,23.108-42.112
      c19.149-19.149,44.612-29.696,71.697-29.696s52.54,10.547,71.697,29.696c19.149,19.149,29.696,44.612,29.696,71.697
      c0,27.085-10.547,52.548-29.696,71.697c-10.923,10.914-25.378,16.572-42.129,23.125c-17.357,6.793-37.043,14.49-58.445,29.773
      c-3.831,2.739-4.719,8.073-1.98,11.904c1.664,2.33,4.284,3.576,6.955,3.576c1.715,0,3.447-0.521,4.949-1.587
      c19.669-14.054,38.306-21.342,54.741-27.776c17.758-6.946,34.534-13.508,47.974-26.948
      c22.374-22.374,34.697-52.122,34.697-83.763S499.635,57.097,477.261,34.722z"
    />
    <path d="M232.115,148.326c-3.337-3.336-8.73-3.336-12.066,0c-3.328,3.337-3.328,8.73,0,12.066L351.59,291.934
      c1.664,1.664,3.849,2.5,6.033,2.5c2.185,0,4.369-0.836,6.033-2.5c3.337-3.336,3.337-8.73,0-12.066L232.115,148.326z"
    />
    <path d="M208.196,172.245c-3.328-3.336-8.73-3.336-12.066,0c-3.328,3.328-3.328,8.73,0,12.066l131.55,131.541
      c1.672,1.673,3.857,2.5,6.033,2.5c2.185,0,4.369-0.828,6.042-2.5c3.328-3.328,3.328-8.73,0-12.066L208.196,172.245z"
    />
    <path d="M381.781,160.666c-12.331,4.48-25.088,9.114-35.268,16.777c-3.763,2.833-4.514,8.183-1.681,11.947
      c2.833,3.772,8.201,4.531,11.947,1.69c8.141-6.127,19.166-10.129,30.831-14.37c15.078-5.478,30.686-11.153,41.822-22.289
      c9.6-9.6,14.882-22.366,14.882-35.934c0-13.568-5.282-26.334-14.882-35.934c-19.823-19.814-52.045-19.797-71.859,0
      c-9.771,9.771-16.043,24.841-23.287,42.274c-7.689,18.466-16.393,39.398-31.437,60.459c-2.739,3.831-1.852,9.156,1.988,11.896
      c1.493,1.075,3.234,1.596,4.949,1.596c2.662,0,5.282-1.246,6.946-3.575c16.171-22.63,25.668-45.466,33.306-63.812
      c6.562-15.77,12.22-29.38,19.601-36.77c13.141-13.15,34.569-13.158,47.727,0c6.374,6.383,9.882,14.857,9.882,23.868
      c0,9.011-3.507,17.493-9.882,23.868C408.909,150.81,395.733,155.597,381.781,160.666z"
    />
  </SvgIcon>
);

export default RopeIcon;
