import {
  ArchivedIcon, CompletedIcon, InProgressIcon, PausedIcon, OnTheWallIcon, TeardownIcon, ToDoIcon,
} from 'assets/icons';


export const ASSIGNMENT_STATUS = Object.freeze({
  TO_DO: 'TO_DO',
  IN_PROGRESS: 'IN_PROGRESS',
  PAUSED: 'PAUSED',
  ON_THE_WALL: 'ON_THE_WALL',
  COMPLETED: 'COMPLETED',
  MARKED_FOR_TEARDOWN: 'MARKED_FOR_TEARDOWN',
  ARCHIVED: 'ARCHIVED',
});

export const ASSIGNMENT_STATUS_VALUES = [
  ASSIGNMENT_STATUS.TO_DO,
  ASSIGNMENT_STATUS.IN_PROGRESS,
  ASSIGNMENT_STATUS.PAUSED,
  ASSIGNMENT_STATUS.ON_THE_WALL,
  ASSIGNMENT_STATUS.COMPLETED,
  ASSIGNMENT_STATUS.MARKED_FOR_TEARDOWN,
  ASSIGNMENT_STATUS.ARCHIVED,
];

export const ASSIGNMENT_STATUS_LABELS = Object.freeze({
  TO_DO: 'To Do',
  IN_PROGRESS: 'In Progress',
  PAUSED: 'Paused',
  ON_THE_WALL: 'On the Wall',
  COMPLETED: 'Completed',
  MARKED_FOR_TEARDOWN: 'Marked For Teardown',
  ARCHIVED: 'Removed',
});

export const ASSIGNMENT_STATUS_ICONS = Object.freeze({
  TO_DO: ToDoIcon,
  IN_PROGRESS: InProgressIcon,
  PAUSED: PausedIcon,
  ON_THE_WALL: OnTheWallIcon,
  COMPLETED: CompletedIcon,
  MARKED_FOR_TEARDOWN: TeardownIcon,
  ARCHIVED: ArchivedIcon,
});

export const ASSIGNMENT_STATUS_OPTIONS = ASSIGNMENT_STATUS_VALUES.map((status) => ({
  value: status,
  label: ASSIGNMENT_STATUS_LABELS[status],
  icon: ASSIGNMENT_STATUS_ICONS[status],
}));
