import React from 'react';
import { uniq } from 'lodash';
import { ListItemAvatar } from '@mui/material';

import UserAvatar from 'common/UserAvatar';
import { CLIMB_TYPES } from 'config/climbs';
import { BOULDER_SCALES, ROUTE_SCALES } from 'config/grades';
import { ROLES } from 'config/staffRoles';
import StaffService from 'service/StaffService';
import { fieldSort } from 'service/utility';

import GradesService from './GradesService';


const getRouteScale = (account) => account.routeScale || ROUTE_SCALES.YDS.value;

const getBoulderScale = (account) => account.boulderScale || BOULDER_SCALES.VSCALE.value;

const getRouteGradeNumbers = (account) => {
  const grades = account.routeGrades.map((g) => g.grade);
  const routeGradeNumbers = uniq(grades.map(GradesService.getGradeNumber));

  return GradesService.getSortedGrades(routeGradeNumbers);
};

const getRouteGrades = (account) => {
  const grades = account.routeGrades.map((g) => g.grade);

  return GradesService.getSortedGrades(grades);
};

const getBoulderGrades = (account) => {
  const grades = account.boulderGrades.map((g) => g.grade);

  return GradesService.getSortedGrades(grades);
};

const getGrades = (account, climbType) => (
  climbType === CLIMB_TYPES.ROUTES ? getRouteGrades(account) : getBoulderGrades(account)
);

const getGradeOptions = (account, climbType) => (
  GradesService.getGradeOptions(getGrades(account, climbType))
);

const getStaff = (account) => (
  account.userAccounts.map(StaffService.getStaffFromUserAccount).sort(fieldSort('name'))
);

const getStaffWithRole = (account, role) => getStaff(account).filter((u) => u.role === role);

const getAdmins = (account) => getStaffWithRole(account, ROLES.ADMIN);

const getSetters = (account) => getStaffWithRole(account, ROLES.SETTER);

const getStrippers = (account) => getStaffWithRole(account, ROLES.STRIPPER);

const getStaffOptions = (users) => (
  users.map((user) => ({
    value: user.id,
    label: user.name,
    icon: () => (
      <ListItemAvatar>
        <UserAvatar user={user} />
      </ListItemAvatar>
    ),
  }))
);

const getAdminOptions = (account) => getStaffOptions(getAdmins(account));

const getSetterOptions = (account) => getStaffOptions(getSetters(account));

const getStripperOptions = (account) => getStaffOptions(getStrippers(account));


const AccountService = {
  getRouteScale,
  getBoulderScale,
  getRouteGradeNumbers,
  getRouteGrades,
  getBoulderGrades,
  getGrades,
  getGradeOptions,
  getAdmins,
  getAdminOptions,
  getSetters,
  getSetterOptions,
  getStrippers,
  getStripperOptions,
};


export default AccountService;
