/**
 * Configuration file for all of our climb grading systems.
 * Routes and Boulders/Problems have different grading scales and are enforced at the database level.
 *
 * See documentation link below for more information.
 *
 * @see - https://climbinghouse.com/grades-charts/
 */
export const ROUTE_SCALES = {
  AUSTRALIAN: {
    value: 'AUSTRALIAN',
    label: 'Australian Scale',
  },
  FRENCH: {
    value: 'FRENCH',
    label: 'French Scale',
  },
  YDS: {
    value: 'YDS',
    label: 'USA Scale',
    chartMin: '5.8',
  },
};

export const BOULDER_SCALES = {
  FONT_SCALE: {
    value: 'FONT_SCALE',
    label: 'French Scale',
  },
  VSCALE: {
    value: 'VSCALE',
    label: 'V Scale',
  },
};
