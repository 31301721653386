import { createReducer } from 'service/utility';

import { SET_CURRENT_LOCATION, AUTH_LOGGED_OUT_SUCCESS } from '../actions';


const initialState = {};

const currentLocation = createReducer(initialState, {
  [SET_CURRENT_LOCATION]: (state, { location }) => location,
  [AUTH_LOGGED_OUT_SUCCESS]: (state) => initialState,
});


export default currentLocation;
