import React from 'react';
import { Switch, Route } from 'react-router-dom';

import { SuspenseFallback } from 'common/statusIndicators';
import NAVIGATION from 'config/navigation';


const Login = React.lazy(() => import('./auth/views/Login'));
const SignUp = React.lazy(() => import('./auth/views/SignUp'));
const MainApp = React.lazy(() => import('./MainApp'));


const Routes = () => (
  <React.Suspense fallback={<SuspenseFallback />}>
    <Switch>
      <Route
        path={NAVIGATION.LOGIN}
        component={Login}
      />
      <Route
        path={NAVIGATION.SIGNUP}
        component={SignUp}
      />
      <Route
        path="/"
        component={MainApp}
      />
    </Switch>
  </React.Suspense>
);


export default Routes;
