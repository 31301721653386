import { put, select, call } from 'redux-saga/effects';

import Api from 'service/api';
import { getLocationLocale } from 'service/i18n';
import { setNewLocale } from 'service/utility';

import { setCurrentLocationAction, setCurrentAccountAction } from '../actions';
import { currentLocationSelector } from '../selectors';


export function *queryAndSetLocation(namespace, locationId) {
  const location = yield call(Api.locations.get, namespace, locationId);

  console.log('Setting current location to ', location);
  yield put(setCurrentLocationAction(location));

  return location;
}

export function *changeLocation(location) {
  const oldLocation = yield select(currentLocationSelector);

  console.log('Setting current location to ', location);
  yield put(setCurrentLocationAction(location));

  const currentLocale = getLocationLocale(oldLocation);
  const newLocale = getLocationLocale(location);

  if (newLocale !== currentLocale) {
    setNewLocale(newLocale);
  }
}

export function *setAccount(account) {
  console.log('Setting current account to ', account);
  yield put(setCurrentAccountAction(account));
}

export function *queryAndSetAccount(namespace, accountId) {
  const account = yield call(Api.accounts.get, namespace, accountId);

  yield call(setAccount, account);

  return account;
}

export function *changeAccount(account) {
  yield call(setAccount, account);

  if (account.locations?.length) {
    const location = yield call(Api.locations.get, account.namespace, account.locations[0].id);

    yield call(changeLocation, location);
  }
}
