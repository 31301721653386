export const capital = (str) => {
  if (typeof str !== 'string') return '';
  if (!str.length) return '';

  return str.charAt(0).toUpperCase() + str.substr(1);
};

export const formatInitials = (name) => {
  if (typeof name !== 'string' || !name) return '';

  return name.split(' ').map((s) => s.charAt(0).toUpperCase());
};

export const plural = (word, count) => {
  if (typeof word !== 'string' || !word) return '';

  return `${word}${count === 1 ? '' : 's'}`;
};
