import { take, put, call } from 'redux-saga/effects';

import firebaseService from 'service/firebase';

import { SIGN_OUT, authLoggedOutSuccess } from '../actions';


export function *signOutSaga() {
  while (true) {
    yield take(SIGN_OUT);

    try {
      console.log('Signing out of firebase.');
      yield call(firebaseService.signOut);

      yield put(authLoggedOutSuccess());
    } catch (error) {
      console.log('firebaseService.signOut error: ', error);
    }
  }
}
