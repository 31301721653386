import { createReducer } from 'service/utility';

import { SET_INITIALIZATION_ERROR } from '../actions';


const initialState = null;

const initializationError = createReducer(initialState, {
  [SET_INITIALIZATION_ERROR]: (state, { error }) => error,
});

export default initializationError;
