import { createReducer } from 'service/utility';

import { SET_CURRENT_ACCOUNT, AUTH_LOGGED_OUT_SUCCESS } from '../actions';


const initialState = {};

const currentAccount = createReducer(initialState, {
  [SET_CURRENT_ACCOUNT]: (state, { account }) => account,
  [AUTH_LOGGED_OUT_SUCCESS]: (state) => initialState,
});


export default currentAccount;
