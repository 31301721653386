// @see - https://mui.com/material-ui/customization/theming/
// @see - https://bareynol.github.io/mui-theme-creator/
const THEME_CONFIG = {
  palette: {
    type: 'light',
    primary: {
      main: '#03A9F4',
      contrastText: '#FFFFFF',
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          padding: '8px 16px',
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        asterisk: {
          whiteSpace: 'nowrap',
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          minWidth: '48px',
        },
      },
    },
  },
};

export default THEME_CONFIG;
