import { isObject, isEqual } from 'lodash';
import { Settings } from 'luxon';
import i18next from 'i18next';


export const makeActionCreator = (type, ...argNames) => (...args) => {
  const action = { type };

  argNames.forEach((arg, index) => {
    action[argNames[index]] = args[index];
  });

  return action;
};

// eslint-disable-next-line default-param-last
export const createReducer = (initialState, handlers) => (state = initialState, action) => {
  if (Object.prototype.hasOwnProperty.call(handlers, action.type)) {
    return handlers[action.type](state, action);
  }

  return state;
};

export const op = (o, fld, dv = null) => (o && typeof o === 'object' ? o[fld] ?? dv : dv);

export const routeWithParams = (route, o) => Object.keys(o).reduce((s, p) => s.replace(new RegExp(`:${p}(?=/|$)`), o[p]), route);

export const objectHasErrors = (o) => Object.values(o).some((e) => e);

export const commaJoin = (arr) => arr.filter((e) => e).join(',');

export const commaSpaceJoin = (arr) => arr.filter((e) => e).join(', ');

export const spaceJoin = (arr) => arr.filter((e) => e).join(' ');

export const dashJoin = (arr) => arr.filter((e) => e).join(' – ');

export const getFakeObjects = (n) => Array.from(new Array(n)).map((e, idx) => ({ id: -idx }));

export const getErrorMessage = (error) => {
  if (typeof error === 'string') return error;
  if (typeof error !== 'object') return JSON.stringify(error);

  const { message: regularErrorMessage, response } = error;

  if (typeof response === 'string') return response;
  if (typeof response === 'object') {
    const { data } = response;

    if (typeof data === 'string') return data;
    if (typeof data === 'object') {
      const { message } = data;

      if (typeof message === 'string') return message;
    }
  }

  if (typeof regularErrorMessage === 'string') return regularErrorMessage;
  if (typeof response !== 'object') return JSON.stringify(response);

  return 'Unknown error';
};

export const isNullish = (x) => ((x ?? null) === null);

export const fieldSort = (field, desc) => (a, b) => {
  const a2 = a[field];
  const a3 = typeof a2 === 'string' ? a2.toUpperCase() : a2;
  const b2 = b[field];
  const b3 = typeof b2 === 'string' ? b2.toUpperCase() : b2;

  return (
    (desc ? a3 > b3 || isNullish(b3) : a3 < b3 || isNullish(a3))
      ? -1
      : (desc ? a3 < b3 || isNullish(a3) : a3 > b3 || isNullish(b3))
        ? 1
        : 0
  );
};

export const copyObjectWithoutRelated = (o) => {
  const o2 = {}, keys = Object.keys(o);

  for (const key of keys) {
    if (!isObject(o[key])) {
      o2[key] = o[key];
    }
  }

  return o2;
};

export const setNewLocale = (newLocale) => {
  if (!['en-AU', 'en-CA', 'de-DE', 'en-US'].includes(newLocale)) throw new Error(`${newLocale} locale not supported`);

  Settings.defaultLocale = newLocale;
  i18next.changeLanguage(newLocale);
};

export const somePropsChanged = (o1, o2, props) => (
  props.some((prop) => !isEqual(o1[prop], o2[prop]))
);

export const rem2px = (rem) => rem * parseFloat(getComputedStyle(document.documentElement).fontSize);

export const randItem = (items) => items[Math.floor(Math.random() * items.length)];

export const randInt = (max) => Math.floor((Math.random() * max) + 1);

export const capitalizeWords = (text = '') => (
  text.split(' ').map((s) => s.charAt(0).toUpperCase() + s.substr(1).toLowerCase()).join(' ')
);

export class ValidationError extends Error {
  constructor(errors) {
    super('Failed to failed validate model');
    this.name = 'ValidationError';
    this.errors = errors;
  }
}

export const getViewportWidth = () => Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
export const getViewportHeight = () => Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0);

export const multipleFilterDisplay = (values, options) => {
  if (!values.length || values.length === options.length) {
    return 'All';
  }

  return commaSpaceJoin(options.filter((o) => values.includes(o.value)).map((o) => o.label));
};
