import React from 'react';
import { PropTypes } from 'prop-types';
import { Avatar, Stack, Typography } from '@mui/material';

import { formatInitials } from 'service/utility/stringFormatters';


const UserAvatar = (props) => {
  const { user, size, showName } = props;

  if (!user) return null;

  return (
    <Stack direction="row" alignItems="center" spacing={1}>
      <Avatar
        src={user.imageURL}
        sx={{
          width: size,
          height: size,
          borderRadius: size,
          fontSize: 16,
        }}
      >
        {formatInitials(user.name)}
      </Avatar>
      {showName && (
        <Typography>
          {user.name}
        </Typography>
      )}
    </Stack>
  );
};

UserAvatar.propTypes = {
  showName: PropTypes.bool,
  size: PropTypes.number,
  user: PropTypes.object,
};

UserAvatar.defaultProps = {
  showName: false,
  size: 36,
};

export default UserAvatar;
