import React from 'react';
import { SvgIcon } from '@mui/material';

const ToDoIcon = (props) => (
  <SvgIcon viewBox="0 0 23 20" {...props}>
    <path d="M9.63 10.1C10.0107 9.26339 10.5525 8.50997 11.2245 7.88279C11.8964 7.2556 12.6853 6.76694 13.5462 6.44471C14.407 6.12247 15.3229 5.97298 16.2415 6.00478C17.1601 6.03657 18.0635 6.24902 18.9 6.63C19.7366 7.01072 20.49 7.55253 21.1172 8.22448C21.7444 8.89643 22.2331 9.68535 22.5553 10.5462C22.8775 11.407 23.027 12.3229 22.9952 13.2415C22.9634 14.1601 22.751 15.0635 22.37 15.9C21.8155 17.1241 20.9196 18.1621 19.7896 18.8893C18.6596 19.6166 17.3438 20.0023 16 20C13.3 20 10.83 18.44 9.67 16H0V14C0.06 12.86 0.84 11.93 2.34 11.18C3.84 10.43 5.72 10.04 8 10C8.57 10 9.11 10.05 9.63 10.1ZM8 0C9.12 0.03 10.06 0.42 10.81 1.17C11.56 1.92 11.93 2.86 11.93 4C11.93 5.14 11.56 6.08 10.81 6.83C10.06 7.58 9.12 7.95 8 7.95C6.88 7.95 5.94 7.58 5.19 6.83C4.44 6.08 4.07 5.14 4.07 4C4.07 2.86 4.44 1.92 5.19 1.17C5.94 0.42 6.88 0.03 8 0ZM16 18C17.3261 18 18.5979 17.4732 19.5355 16.5355C20.4732 15.5979 21 14.3261 21 13C21 11.6739 20.4732 10.4021 19.5355 9.46447C18.5979 8.52678 17.3261 8 16 8C14.6739 8 13.4021 8.52678 12.4645 9.46447C11.5268 10.4021 11 11.6739 11 13C11 14.3261 11.5268 15.5979 12.4645 16.5355C13.4021 17.4732 14.6739 18 16 18ZM15 10H16.5V12.82L18.94 14.23L18.19 15.53L15 13.69V10Z" />
  </SvgIcon>
);

export default ToDoIcon;
