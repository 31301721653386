import { take, put, call, select } from 'redux-saga/effects';

import Api from 'service/api';

import {
  SIGN_IN, setCurrentUser, setCurrentStaff,
  authLoggedInSuccess, setInitializationFinishedAction,
} from '../actions';
import { currentAccountSelector, currentLocationSelector } from '../selectors';
import { queryAndSetAccount, queryAndSetLocation } from './common';


export function *signInSaga() {
  while (true) {
    const { user } = yield take(SIGN_IN);
    console.log('Starting login flow.');

    console.log('Setting current user to ', user);
    yield put(setCurrentUser(user));

    const staff = yield call(Api.profile.get);
    console.log('Setting current staff to ', staff);
    yield put(setCurrentStaff(staff));

    yield put(authLoggedInSuccess(user));

    let currentAccount = yield select(currentAccountSelector);
    const { namespace, id: accountId } = currentAccount;

    if (namespace && accountId) {
      currentAccount = yield call(queryAndSetAccount, namespace, accountId);

      let currentLocation = yield select(currentLocationSelector);
      const { id: locationId } = currentLocation;

      if (locationId) {
        currentLocation = yield call(queryAndSetLocation, namespace, locationId);
      }
    }

    yield put(setInitializationFinishedAction());
  }
}
