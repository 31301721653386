import axios from 'axios';

import AppConfig from 'config/app';
import firebaseService from 'service/firebase';


export const initAxios = async () => {
  const token = await firebaseService.getIdToken();
  const instance = axios.create({
    baseURL: AppConfig.apiRoot,
    headers: { Authorization: token },
  });

  return instance;
};

export const initAxiosWidgets = async (namespace) => {
  const axiosConfig = {
    baseURL: AppConfig.widgetsApiRoot,
    headers: {
      Authorization: namespace,
      'X-Api-Key': AppConfig.widgetsApiKey,
    },
  };

  const instance = axios.create(axiosConfig);

  return instance;
};
