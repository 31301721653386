import React from 'react';
import { PropTypes } from 'prop-types';
import {
  Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions,
} from '@mui/material';


class DeleteButton extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showDialog: false,
    };
  }

  openDialog = () => {
    this.setState({
      showDialog: true,
    });
  };

  closeDialog = () => {
    this.setState({
      showDialog: false,
    });
  };

  handleDeleteClick = () => {
    this.closeDialog();
    this.props.onDelete();
  };

  render() {
    const { modelName } = this.props;
    const { showDialog } = this.state;

    return (
      <>
        <Button
          onClick={this.openDialog}
          color="error"
          sx={{
            paddingX: 2,
            fontWeight: 500,
          }}
        >
          {'Delete'}
        </Button>
        {showDialog && (
          <Dialog
            open
            onClose={this.closeDialog}
          >
            <DialogTitle>
              {`Delete ${modelName}?`}
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                {`Are you sure you want to delete this ${modelName}?`}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={this.closeDialog}>
                {'Cancel'}
              </Button>
              <Button
                color="error"
                onClick={this.handleDeleteClick}
              >
                {'Delete'}
              </Button>
            </DialogActions>
          </Dialog>
        )}
      </>
    );
  }
}

DeleteButton.propTypes = {
  modelName: PropTypes.string.isRequired,
  onDelete: PropTypes.func.isRequired,
};


export default DeleteButton;
