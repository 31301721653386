import React from 'react';
import { SvgIcon } from '@mui/material';

const RolesIcon = (props) => (
  <SvgIcon viewBox="0 0 19 22" {...props}>
    <path
      d="M9.65503 20.7501V1.25M1 11H18.3101M8.75503 1.43L1 4.62501V12.5C1 16.7201 9.65503 20.7501 9.65503 20.7501C9.65503 20.7501 18.3101 16.7201 18.3101 12.5V4.62501L10.555 1.43C10.2699 1.31118 9.96397 1.25 9.65503 1.25C9.34609 1.25 9.0402 1.31118 8.75503 1.43V1.43Z"
      fill="none"
      stroke="black"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);

export default RolesIcon;
