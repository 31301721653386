import { createReducer } from 'service/utility';

import { SET_CURRENT_STAFF, AUTH_LOGGED_OUT_SUCCESS } from '../actions';


const initialState = {};

const currentStaff = createReducer(initialState, {
  [SET_CURRENT_STAFF]: (state, { staff }) => staff,
  [AUTH_LOGGED_OUT_SUCCESS]: (state) => initialState,
});

export default currentStaff;
