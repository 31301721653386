export const round = (val, decimals) => Number(`${Math.round(`${val}e${decimals}`)}e-${decimals}`);

export const toCents = (value) => value * 100;

export const percentageOfPrice = (price, percentage) => round((price * percentage / 100), 2);

export const leftPadZero = (num, width) => num.toString().padStart(width, 0);

export const getOrdinalSuffix = (n) => (
  n > 10 && n < 20 ? 'th' : n % 10 === 1 ? 'st' : n % 10 === 2 ? 'nd' : n % 10 === 3 ? 'rd' : 'th'
);

export const ordinal = (n) => `${n}${getOrdinalSuffix(n)}`;

const getSecureRandom = () => {
  const rng = window.crypto || window.msCrypto;

  if (rng === undefined) {
    return Math.random();
  }

  return rng.getRandomValues(new Uint32Array(1))[0] / 4294967296;
};

export const getRandomInt = (max = 4294967296) => (
  Math.floor(getSecureRandom() * Math.floor(max))
);
