import React from 'react';
import { SvgIcon } from '@mui/material';

const PausedIcon = (props) => (
  <SvgIcon viewBox="0 0 22 22" {...props}>
    <path d="M10.9998 21.8331C5.01984 21.8266 0.173004 16.9798 0.166504 10.9998V10.7831C0.285671 4.83023 5.18775 0.088476 11.1407 0.166476C17.0958 0.246643 21.87 5.11623 21.831 11.0702C21.792 17.0253 16.9549 21.8331 10.9998 21.8331ZM10.9825 19.6665H10.9998C13.2976 19.6642 15.5005 18.7495 17.124 17.1235C18.7476 15.4975 19.659 13.2933 19.6578 10.9955C19.6567 8.69769 18.7431 6.4944 17.1179 4.87002C15.4927 3.24564 13.289 2.33315 10.9912 2.33315C8.69338 2.33315 6.48963 3.24564 4.86444 4.87002C3.23925 6.4944 2.32565 8.69769 2.32451 10.9955C2.32336 13.2933 3.23475 15.4975 4.85832 17.1235C6.48188 18.7495 8.68471 19.6642 10.9825 19.6665ZM14.2498 15.3331H12.0832V6.66648H14.2498V15.3331ZM9.9165 15.3331H7.74984V6.66648H9.9165V15.3331Z" />
  </SvgIcon>
);

export default PausedIcon;
