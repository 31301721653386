import { combineReducers } from 'redux';

import currentAccount from './currentAccount';
import currentLocation from './currentLocation';
import currentStaff from './currentStaff';
import currentUser from './currentUser';
import embedNamespace from './embedNamespace';
import initializationError from './initializationError';
import initializationFinished from './initializationFinished';
import landingPage from './landingPage';
import serviceWorker from './serviceWorker';


const rootReducer = combineReducers({
  currentAccount,
  currentLocation,
  currentStaff,
  currentUser,
  embedNamespace,
  initializationError,
  initializationFinished,
  landingPage,
  serviceWorker,
});


export default rootReducer;
