import React from 'react';
import { SvgIcon } from '@mui/material';

const ColorsIcon = (props) => (
  <SvgIcon viewBox="0 0 24 24" {...props}>
    <path
      d="M12 0.960938C5.90842 0.960938 0.959961 5.9094 0.959961 12.0009C0.959961 18.0925 5.90842 23.0409 12 23.0409C18.0915 23.0409 23.04 18.0925 23.04 12.0009C23.04 5.9094 18.0915 0.960938 12 0.960938ZM11.52 1.93312V6.26531C10.2926 6.36737 9.17368 6.854 8.28465 7.60687L5.22559 4.54781C6.90512 3.0206 9.09596 2.04643 11.52 1.93312ZM12.48 1.94344C14.902 2.05827 17.0955 3.0212 18.7743 4.54781L15.7153 7.60687C14.826 6.8538 13.7073 6.36481 12.48 6.2625V1.94344ZM4.54684 5.22656L7.6059 8.28562C6.85302 9.17465 6.3664 10.2936 6.26434 11.5209H1.93215C2.04546 9.09694 3.01963 6.90609 4.54684 5.22656ZM19.4531 5.22656C20.9797 6.90544 21.9426 9.0989 22.0575 11.5209H17.7384C17.6361 10.2936 17.1471 9.17488 16.394 8.28562L19.4531 5.22656ZM12 7.20094C14.6566 7.20094 16.8 9.34429 16.8 12.0009C16.8 14.6576 14.6566 16.8009 12 16.8009C9.34331 16.8009 7.19996 14.6576 7.19996 12.0009C7.19996 9.34429 9.34331 7.20094 12 7.20094ZM1.93215 12.4809H6.26434C6.3664 13.7083 6.85302 14.8272 7.6059 15.7162L4.54684 18.7753C3.01963 17.0958 2.04546 14.9049 1.93215 12.4809ZM17.7356 12.4809H22.0678C21.9545 14.9049 20.9803 17.0958 19.4531 18.7753L16.394 15.7162C17.1469 14.8272 17.6335 13.7083 17.7356 12.4809ZM8.28465 16.395C9.17368 17.1479 10.2926 17.6345 11.52 17.7366V22.0688C9.09596 21.9554 6.90512 20.9813 5.22559 19.4541L8.28465 16.395ZM15.7153 16.395L18.7743 19.4541C17.0948 20.9813 14.904 21.9554 12.48 22.0688V17.7366C13.7073 17.6345 14.8262 17.1479 15.7153 16.395Z"
      fill="black"
    />
  </SvgIcon>
);

export default ColorsIcon;
