export const initializationFinishedSelector = (state) => state.initializationFinished;
export const initializationErrorSelector = (state) => state.initializationError;
export const serviceWorkerSelector = (state) => state.serviceWorker;
export const currentUserSelector = (state) => state.currentUser;
export const currentStaffSelector = (state) => state.currentStaff;
export const currentAccountSelector = (state) => state.currentAccount;
export const currentLocationSelector = (state) => state.currentLocation;
export const currentUserAccountSelector = (state) => {
  const account = currentAccountSelector(state);
  const staff = currentStaffSelector(state);
  const userAccount = staff.userAccounts.find((ua) => ua.accountId === account.id);

  return userAccount;
};
export const landingPageSelector = (state) => state.landingPage;
export const isAuthenticatedUserSelector = (state) => (
  Boolean(currentUserSelector(state)?.isLogged)
);
export const embedNamespaceSelector = (state) => state.embedNamespace;
export const namespaceSelector = (state) => (
  embedNamespaceSelector(state) || currentAccountSelector(state).namespace
);
export const settingsSelector = (state) => (
  currentAccountSelector(state).accountSettings || {}
);
export const showEmbedSettingSelector = (state) => (
  Boolean(settingsSelector(state)?.general?.showEmbed ?? 0)
);
