import { ASSIGNMENT_STATUS, ASSIGNMENT_STATUS_LABELS } from 'config/assignments';
import { RECENT_DAYS, CLIMB_TYPES } from 'config/climbs';
import { ROUTE_SCALES } from 'config/grades';
import { commaSpaceJoin, copyObjectWithoutRelated } from 'service/utility';
import * as timeUtils from 'service/utility/timeUtils';

import AccountService from './AccountService';
import GradesService from './GradesService';


const getClimbPolygon = (climb) => (
  climb?.climbPolygon ?? null
);

const getImageURL = (climb) => {
  const media = climb?.media?.[0] ?? null;
  if (!media) return null;

  return (media.dataURL || media.imageURL) ?? null;
};

const getGrade = (climb) => (
  climb.finalGrade || climb.targetGrade
);

const getMyWork = (assignments, staff) => (
  assignments.filter((assignment) => assignment.assigneeId === staff.id)
);

const getToApprove = (assignments) => (
  assignments.filter((assignment) => assignment.status === ASSIGNMENT_STATUS.ON_THE_WALL)
);

const getAge = (climb, timeZone) => {
  const { completedDate } = climb;

  if (!completedDate) return '';

  const age = timeUtils.numberOfDaysAgo(completedDate, timeZone);

  return `${age}\u00A0day${age === 1 ? '' : 's'} ago`;
};

const getCompletedDate = (climb) => {
  const { completedDate } = climb;

  if (!completedDate) return '';

  return timeUtils.formatShortDate(completedDate);
};

const isUnassigned = (assignment) => !assignment.assignee;

const isAssigned = (assignment) => Boolean(assignment.assignee);

const isAssignedToUser = (assignment, staff) => (
  AssignmentService.isAssigned(assignment) && assignment.assigneeId === staff.id
);

const isToBeCreated = (assignment) => assignment.status === ASSIGNMENT_STATUS.TO_DO;

const isInProgress = (assignment) => assignment.status === ASSIGNMENT_STATUS.IN_PROGRESS;

const isPaused = (assignment) => assignment.status === ASSIGNMENT_STATUS.PAUSED;

const isOnTheWall = (assignment) => assignment.status === ASSIGNMENT_STATUS.ON_THE_WALL;

const isCompleted = (assignment) => assignment.status === ASSIGNMENT_STATUS.COMPLETED;

const isMarkedForTeardown = (assignment) => assignment.status === ASSIGNMENT_STATUS.MARKED_FOR_TEARDOWN;

const isCompletedAndNotTornDown = (assignment) => (
  isCompleted(assignment) || isMarkedForTeardown(assignment)
);

const isTeardownComplete = (assignment) => assignment.status === ASSIGNMENT_STATUS.ARCHIVED;

const hasFinishedSetting = (assignment) => (
  isOnTheWall(assignment) || isCompleted(assignment) ||
  isMarkedForTeardown(assignment) || isTeardownComplete(assignment)
);

const isVisibleOnClimbPlacement = (assignment) => (
  !isTeardownComplete(assignment)
);

const statusDisplay = (statuses_ = []) => {
  const statuses = Array.isArray(statuses_) ? statuses_ : [statuses_];

  if (!statuses.length || statuses.length === Object.keys(ASSIGNMENT_STATUS).length) {
    return 'All';
  }

  return commaSpaceJoin(statuses.map((status) => ASSIGNMENT_STATUS_LABELS[status] ?? status));
};

const getDifficultyRangeString = (climbs, grades) => {
  if (!climbs.length) return '';

  const sortedGrades = GradesService.getSortedGrades(grades);

  let minIdx = sortedGrades.length, maxIdx = -1;
  for (let i = 0, l = climbs.length; i < l; i += 1) {
    const climb = climbs[i];
    const grade = getGrade(climb);
    const idx = sortedGrades.indexOf(grade);
    if (idx < minIdx) minIdx = idx;
    if (idx > maxIdx) maxIdx = idx;
  }

  return `${sortedGrades[minIdx]} to ${sortedGrades[maxIdx]}`;
};

const getCompletedDateRange = (climbs) => {
  let minCompletedDate = null, maxCompletedDate = null;

  if (climbs.length) {
    const completedDates = climbs.map((climb) => climb.completedDate).filter((e) => e);

    if (completedDates.length) {
      const sortedCompletedDates = completedDates.sort();
      minCompletedDate = timeUtils.formatShortDate(sortedCompletedDates[0]);
      maxCompletedDate = timeUtils.formatShortDate(sortedCompletedDates[sortedCompletedDates.length - 1]);
    }
  }

  return [minCompletedDate, maxCompletedDate];
};

const getMostRecentCompletedDaysAgo = (climbs, timeZone) => {
  if (!climbs.length) return null;

  const completedDates = climbs.map((climb) => climb.completedDate).filter((e) => e);
  if (!completedDates.length) return null;

  const sortedCompletedDates = completedDates.sort();
  const mostRecentCompletedDate = sortedCompletedDates[sortedCompletedDates.length - 1];

  return timeUtils.numberOfDaysAgo(mostRecentCompletedDate, timeZone);
};

const isRecent = (climb, timeZone) => {
  if (!climb.completedDate) return false;

  return timeUtils.numberOfDaysAgo(climb.completedDate, timeZone) <= RECENT_DAYS;
};

const getChartData = (climbs, account) => {
  const routeGradeNumbers = AccountService.getRouteGradeNumbers(account);
  const routeScale = AccountService.getRouteScale(account);
  const { chartMin } = ROUTE_SCALES[routeScale];
  const chartMinIdx = routeGradeNumbers.indexOf(chartMin);
  const routeGradeNumbersToDisplay = [
    `≤${chartMin}`,
    ...routeGradeNumbers.slice(chartMinIdx + 1),
  ];
  const data = routeGradeNumbersToDisplay.map((grade) => ({
    name: grade,
    completed: 0,
    todo: 0,
    teardown: 0,
  }));

  for (let i = 0, l = climbs.length; i < l; i += 1) {
    const climb = climbs[i];
    const grade = getGrade(climb);
    // eslint-disable-next-line no-continue
    if (!grade) continue;
    const gradeNumber = GradesService.getGradeNumber(grade);
    let idx = routeGradeNumbersToDisplay.indexOf(gradeNumber);
    if (idx < 0) idx = 0;
    if (isCompleted(climb)) data[idx].completed++;
    if (isToBeCreated(climb)) data[idx].todo++;
    if (isMarkedForTeardown(climb)) data[idx].teardown++;
  }

  return data;
};

const getRecentClimbs = (climbs, timeZone) => (
  climbs.filter(
    (c) => Boolean(c.completedDate) && timeUtils.numberOfDaysAgo(c.completedDate, timeZone) <= 7
  )
);

const getCompletedClimbs = (climbs) => (
  climbs.filter((c) => c.status === ASSIGNMENT_STATUS.COMPLETED)
);

const getCompletedAndMarkedClimbs = (climbs) => (
  climbs.filter((c) => c.status === ASSIGNMENT_STATUS.COMPLETED || c.status === ASSIGNMENT_STATUS.MARKED_FOR_TEARDOWN)
);

const flatRouteTypes = (climb) => ({
  ...climb,
  routeTypes: climb.routeTypes?.map((rt) => rt.type) ?? [],
});

const getClimbsForWall = (climbs, wallId) => (
  climbs.filter((c) => c.wallId === wallId && !c.zoneId)
);

const getClimbsForZone = (climbs, zoneId) => (
  climbs.filter((c) => c.zoneId === zoneId)
);

const getPayloadForUpdate = (climb) => {
  const { wall, zone, climbPolygonPoly, routeTypes, ...rest } = climb;
  const payload = copyObjectWithoutRelated(rest);

  if (routeTypes) {
    payload.routeTypes = routeTypes;
  }
  if (wall) {
    payload.wallId = wall.id;
  }
  if (zone) {
    payload.zoneId = zone.id;
  }

  return payload;
};

const isRoute = (climb) => (
  climb.climbType === CLIMB_TYPES.ROUTES
);

const isBoulder = (climb) => (
  climb.climbType === CLIMB_TYPES.BOULDERING
);


const AssignmentService = {
  getClimbPolygon,
  getImageURL,
  getGrade,
  getMyWork,
  getToApprove,
  getAge,
  getCompletedDate,
  isUnassigned,
  isAssigned,
  isAssignedToUser,
  isToBeCreated,
  isInProgress,
  isPaused,
  isOnTheWall,
  isCompleted,
  isMarkedForTeardown,
  isCompletedAndNotTornDown,
  isTeardownComplete,
  hasFinishedSetting,
  isVisibleOnClimbPlacement,
  statusDisplay,
  getDifficultyRangeString,
  getCompletedDateRange,
  getMostRecentCompletedDaysAgo,
  isRecent,
  getChartData,
  getRecentClimbs,
  getCompletedClimbs,
  getCompletedAndMarkedClimbs,
  flatRouteTypes,
  getClimbsForWall,
  getClimbsForZone,
  getPayloadForUpdate,
  isRoute,
  isBoulder,
};


export default AssignmentService;
