import { changeAccountSaga } from './changeAccountSaga';
import { changeLocationSaga } from './changeLocationSaga';
import { signInSaga } from './signInSaga';
import { signOutSaga } from './signOutSaga';
import { unauthenticatedSaga } from './unauthenticatedSaga';

const sagas = [
  changeAccountSaga,
  changeLocationSaga,
  signInSaga,
  signOutSaga,
  unauthenticatedSaga,
];

export default sagas;
