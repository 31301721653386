import React from 'react';
import { PropTypes } from 'prop-types';
import classnames from 'classnames';
import { withStyles } from 'tss-react/mui';
import Button from '@mui/material/Button';


const LinkButton = ({ classes, children, className, inParagraph, ...rest }) => (
  <Button
    className={classnames(className, { [classes.inParagraph]: inParagraph })}
    {...rest}
  >
    {children}
  </Button>
);

LinkButton.propTypes = {
  children: PropTypes.any,
  classes: PropTypes.object.isRequired,
  className: PropTypes.string.isRequired,
  inParagraph: PropTypes.bool,
};


const styles = (theme, props) => ({
  root: {
    textTransform: 'inherit !important',
    fontWeight: 'inherit !important',
    padding: 'inherit !important',
    textDecoration: 'underline !important',
    color: 'inherit !important',
    '&:hover': {
      backgroundColor: 'transparent !important',
    },
  },
  inParagraph: {
    fontSize: 'inherit',
    lineHeight: 'inherit',
    verticalAlign: 'baseline',
  },
});

const LinkButton2 = withStyles(LinkButton, styles);


export default LinkButton2;
