import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { ThemeProvider, createTheme, responsiveFontSizes } from '@mui/material/styles';
import { ToastContainer, toast, Slide } from 'react-toastify';

import { serviceWorkerSelector } from 'app/redux/selectors';
import { EmbedButton } from 'common/buttons';
import THEME_CONFIG from 'config/theme';
// eslint-disable-next-line no-unused-vars
import firebaseService from 'service/firebase';

import Routes from './Routes';

import 'react-toastify/dist/ReactToastify.css';


const App = () => {
  const serviceWorker = useSelector(serviceWorkerSelector);

  const updateServiceWorker = () => {
    const registrationWaiting = serviceWorker.registration.waiting;

    if (registrationWaiting) {
      registrationWaiting.postMessage({ type: 'SKIP_WAITING' });

      registrationWaiting.addEventListener('statechange', (e) => {
        if (e.target.state === 'activated') {
          window.location.reload();
        }
      });
    }
  };

  if (serviceWorker.initialized) {
    console.log('App: Service Worker is initialized for the first time!');
  }

  if (serviceWorker.updated) {
    updateServiceWorker();

    return null;
  }

  const theme = responsiveFontSizes(createTheme(THEME_CONFIG));

  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <Routes />
        <EmbedButton />
      </BrowserRouter>
      <ToastContainer
        autoClose={false}
        draggable={false}
        hideProgressBar
        theme="colored"
        type={toast.TYPE.INFO}
        position={toast.POSITION.BOTTOM_CENTER}
        transition={Slide}
        className="toast-container"
        toastClassName="toast"
        bodyClassName="toast-body"
        progressClassName="toast-progress"
      />
    </ThemeProvider>
  );
};


export default App;
