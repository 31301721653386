import React from 'react';
import { SvgIcon } from '@mui/material';

const CompletedIcon = (props) => (
  <SvgIcon viewBox="0 0 48 48" {...props}>
    <polygon points="18,33.172 6,21.172 3.171,24 18,38.828 44.829,12 42,9.172" />
  </SvgIcon>
);

export default CompletedIcon;
