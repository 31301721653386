import React from 'react';
import { SvgIcon } from '@mui/material';

const ClimbsIcon = (props) => (
  <SvgIcon viewBox="0 0 104 81" {...props}>
    <path d="M70.057 16.0609C71.883 25.7915 63.036 32.1341 55.6895 35.0886C53.5283 35.9578 51.1215 36.0253 48.8973 35.3326C36.8811 31.5902 33.0265 23.9498 32.6397 17.0023C32.2928 10.7705 38.7272 7.26596 44.9163 8.07323L52.3381 9.0413H59.449C64.2193 9.0413 69.1771 11.3725 70.057 16.0609Z" stroke="black" strokeWidth="4.19355" />
    <path d="M34.9927 42.0416C29.8946 40.1989 24.7597 36.5767 20.1936 33.1357C13.9702 28.4456 6.22943 31.3564 6.47209 39.1454C6.93002 53.8447 15.142 66.2325 21.6607 67.9054C22.5708 68.139 23.5139 68.2321 24.4532 68.2563C30.0898 68.4018 38.6862 63.4324 42.5572 60.7133C46.4152 58.3915 47.6062 55.0812 47.4996 51.8365C47.3103 46.0754 40.4136 44.001 34.9927 42.0416Z" stroke="black" strokeWidth="4.19355" />
    <path d="M65.5409 50.2044L59.5441 56.2012C56.9205 58.8248 55.1477 62.4807 56.2591 66.0207C59.2649 75.5952 69.9247 77.3048 76.5958 76.7704C77.524 76.696 78.4501 76.6587 79.3811 76.6757C87.9176 76.8319 101.913 73.8321 98.9705 60.2987C96.3137 48.0774 82.4937 45.9598 73.0331 46.5725C70.1688 46.758 67.5706 48.1747 65.5409 50.2044Z" stroke="black" strokeWidth="4.19355" />
    <circle cx="25.7112" cy="49.6106" r="4.46898" transform="rotate(5.82922 25.7112 49.6106)" stroke="black" strokeWidth="1.94303" />
    <circle cx="77.0404" cy="62.63" r="4.46898" stroke="black" strokeWidth="1.94303" />
    <circle cx="50.6153" cy="19.923" r="4.46898" stroke="black" strokeWidth="1.94303" />
  </SvgIcon>
);

export default ClimbsIcon;
