import NAVIGATION from 'config/navigation';


export const endsWithSlash = (pathname) => (/\/$/).test(decodeURIComponent(pathname));

export const isEmbed = (location = window.location) => (/\/embed$/).test(location.pathname);

export const stripEmbed = (pathname) => pathname.replace(/\/embed$/, '');

export const getNewURL = (url) => {
  if (typeof url !== 'string') return null;

  const [pathname, search] = url.split('?');
  const pathname2 = stripEmbed(pathname);
  const url2 = `${pathname2}${isEmbed(window.location) ? `${endsWithSlash(pathname2) ? '' : '/'}embed` : ''}${search ? `?${search}` : ''}`;

  return url2;
};

export const navigateTo = (history, to, state) => {
  if (state) {
    history.push(getNewURL(to), state);
  } else {
    history.push(getNewURL(to));
  }
};

export const getPathname = (location = window.location) => stripEmbed(location.pathname);

export const isEmbeddable = (location = window.location) => {
  const pathname = getPathname(location);

  return (
    new RegExp(`^${NAVIGATION.MAP}\\b`).test(pathname)
  );
};

export const getEmbedURL = (url, withSearch = true) => {
  if (typeof url !== 'string') return null;

  const [pathname, search] = url.split('?');
  const pathname2 = stripEmbed(pathname);
  const url2 = `${pathname2}${endsWithSlash(pathname2) ? '' : '/'}embed${withSearch && search ? `?${search}` : ''}`;

  return url2;
};
