import React from 'react';
import { PropTypes } from 'prop-types';
import classnames from 'classnames';
import { withStyles } from 'tss-react/mui';
import { Button } from '@mui/material';


const BottomFixedButton = ({ text, onClick, disabled, className, classes, fixed }) => (
  <div className={classnames(className, { [classes.fixed]: fixed })}>
    <Button
      variant="contained"
      fullWidth
      onClick={onClick}
      disabled={disabled}
    >
      {text}
    </Button>
  </div>
);

BottomFixedButton.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  fixed: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
};

BottomFixedButton.defaultProps = {
  disabled: false,
  fixed: true,
};


const styles = (theme) => ({
  root: {
    borderTop: '1px solid #B5B5B5',
    borderRadius: 0,
    width: '100%',
    padding: theme.spacing(2, 3),
    backgroundColor: '#fff',
  },
  fixed: {
    position: 'absolute',
    left: 0,
    bottom: 0,
  },
});

const BottomFixedButton2 = withStyles(BottomFixedButton, styles);


export default BottomFixedButton2;
