import { take, call } from 'redux-saga/effects';

import { CHANGE_ACCOUNT } from '../actions';
import { changeAccount } from './common';


export function *changeAccountSaga() {
  while (true) {
    const { account } = yield take(CHANGE_ACCOUNT);

    yield call(changeAccount, account);
  }
}
